import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmsTemplateService {
  constructor(private router: Router, private http: HttpClient) { }

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

 

  GetSmsTemplate(): Observable<any> {
    const body = new HttpParams({ });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}SmsTemplate/GetSmsTemplate`, body, options);
  }

  GetSmsTemplateByID(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}SmsTemplate/GetSmsTemplateByID`, body, options);
  }

  InsertSmsTemplate(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}SmsTemplate/InsertSmsTemplate`, body, options);
  }

  UpdateSmsTemplate(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}SmsTemplate/UpdateSmsTemplate`, body, options);
  }

  DeleteSmsTemplate(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}SmsTemplate/DeleteSmsTemplate`, body, options);
  }

  SendSMS(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}SmsTemplate/SendSMS`, body, options);
  }
}
