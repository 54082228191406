import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.css']
})
export class WebFooterComponent {
  constructor(private router: Router) {} 

  redirectTo(page){
    this.router.navigate([page]);
  }
}
