import { Component, HostListener, } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from 'src/app/services/common/log.service';
import { CompanyService } from 'src/app/services/company.service';
import { DateService } from 'src/app/services/date.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent {
  constructor(private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private date: DateService,
    private logService:LogService) { }

  company: FormGroup;
  companyForm: FormGroup;

  allCompanyData: any;
  states:any =[];

  companyID: string = "";
  redirectView: string = "";

  showCompanyNameError = 0;
  showGstin = 0;
  showCaption = 0;
  showCessAndDealer = 0;

  FromPopup = "TRUE";

  isCompanyExist = 0;
  isCompanySucess = 0;
  isCompanyUpdate = 0;
  isCompanyFailure = 0;
  packageID : number = 0;
  isLimitReachedErr = 0;

  ngOnInit(): void {
    try{
      this.companyID = "";
      this.route.queryParamMap.subscribe(params => {
        if (params.has('companyID')) {
          this.companyID = params.get('companyID');
        }
      });
      this.packageID = parseInt(sessionStorage.getItem("pid"));
      console.log(this.packageID)
      this.InitializerMethod();  
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Add Company","ngOnInit");
      console.log("Error in ngOnInit : " + ex);
    }      
  }

  InitializerMethod(){
    try{
      this.GetStates();    
      this.GetAndSetCompany();
      this.GetAllCompanies();

      this.showCompanyNameError = 0;
      this.showGstin = 0;
      this.showCaption = 0;
      this.showCessAndDealer = 0;
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Add Company","InitializerMethod");
      console.log("Error in InitializerMethod : " + ex);
    }     
  }

  GetAndSetCompany() {    
    try{
      this.company = new FormGroup({
        Name: new FormControl(),
        StreetLine1:new FormControl(),
        StreetLine2:new FormControl(),
        City:new FormControl(),
        State:new FormControl(),
        PinNumber:new FormControl(),
        PhoneNumber:new FormControl(),
        MobileNumber:new FormControl(),
        Email:new FormControl(),
        Fax:new FormControl(),
        PanNumber:new FormControl(),
        FyStartDate:new FormControl(),
        EnableGST:new FormControl("N"),
        EnableCess:new FormControl("N"),
        Caption:new FormControl(),
        TypeOfDealer:new FormControl(""),
        GSTIN:new FormControl(),
        SubscriberID : new FormControl(sessionStorage.getItem("subscriberID"))       
      }); 
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Add Company","GetAndSetCompany");
      console.log("Error in GetAndSetCompany : " + ex);
    }        
  }

  GetStates() {
    try {
      this.states = [];
      this.companyService.GetStates().subscribe(
      (result) => {
        for (var i = 0; i < result.length; i++) {
          this.states.push({ key: result[i].stateId, value: result[i].name });
        }
      }, (err: Error) => {
        console.log(err.message);
      });
    }
    catch(ex) {
      this.logService.InsertExceptionLog(ex,"Add Company","GetStates");
      console.log("Error in GetStates : " + ex);
    }
  }

  GetAllCompanies() {
    try {
      this.companyForm = new FormGroup({
        SubscriberID: new FormControl(sessionStorage.getItem("subscriberID"))
      });

      this.companyService.GetAllCompanies(this.companyForm.value).subscribe(
        (result) => {
          this.allCompanyData = result;
        }, (err: Error) => {
          console.log(err.message);
        });
    }
    catch (ex) {
      this.logService.InsertExceptionLog(ex,"Add Company","GetAllCompanies");
      console.log("Error in GetAllCompanies : " + ex);
    }
  }

  ShowGstin(){
    try{
      if(this.company.value.TypeOfDealer == "Composite" || this.company.value.TypeOfDealer == "Regular"){
        this.showGstin = 1;
      }
      else{
        this.showGstin = 0;
      }
    }
    catch(ex){
      this.logService.InsertExceptionLog(ex,"Add Company","ShowGstin");
      console.log("Error in ShowGstin : " + ex);
    }
  }

  ShowCaption(){
    try{
      if(this.company.value.EnableCess == "Y"){
        this.showCaption = 1;
      }
      else{
        this.showCaption = 0;
      }
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Add Company","ShowCaption");
      console.log("Error in ShowCaption : " + ex);
    }
  }

  ShowCessAndDealer(){
    try{
      if(this.company.value.EnableGST == "Y"){
        this.showCessAndDealer = 1;   
        this.ShowGstin();    
        this.ShowCaption(); 
      }
      else{
        this.showCessAndDealer = 0;
        this.showGstin = 0;
        this.showCaption = 0;
      }
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Add Company","ShowCessAndDealer");
      console.log("Error in ShowCessAndDealer : " + ex);
    }
  }

  redirectToHome() {
    this.router.navigate(
      ['view-company'],
      { queryParams: { subscriberID: sessionStorage.getItem("subscriberID")} }
    );    
  }

  ConvertToDate(element){
    try{      
      if (this.company.value.FyStartDate != "") {
        this.company.value.FyStartDate = this.date.ConvertToDate(this.company.value.FyStartDate);
      }          
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Add Company","ConvertToDate");
      console.log("Error in ConvertToDate : " + ex);
    }
  }

  SetDate(element){
    try{
      if (this.company.value.FyStartDate == "" || this.company.value.FyStartDate == null) {
        this.company.value.FyStartDate = this.date.SetInitDate();
      }          
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Add Company","SetDate");
      console.log("Error in SetDate : " + ex);
    }
  }

  // Add New Company
  SaveNewCompany() {
    try {
      this.showCompanyNameError = 0;
      this.isLimitReachedErr = 0;
      
      if(this.packageID == 1 && this.allCompanyData.length >= 1){        
        this.isLimitReachedErr = 1;
        return;        
      }
      else if(this.packageID == 2 && this.allCompanyData.length >= 2){
        this.isLimitReachedErr = 1;
        return;
      }
      else if(this.packageID == 3 && this.allCompanyData.length >= 2){
        this.isLimitReachedErr = 1;
        return;
      }
      else if(this.packageID == 4 && this.allCompanyData.length >= 3){
        this.isLimitReachedErr = 1;
        return;
      }

      if (this.company.value.Name == "" || this.company.value.Name == null) {
        this.showCompanyNameError = 1;
      }
      else {
        for (var i = 0; i < this.allCompanyData.length; i++) {
          if (this.allCompanyData[i].name.toLowerCase() == this.company.value.Name.toLowerCase()) {
            this.isCompanyExist = 1;
            break;
          }
        }

        if (this.isCompanyExist != 1) {          
          this.companyService.InsertCompany(this.company.value).subscribe(
            (result) => {
              if(result != 0){
                this.isCompanySucess = 1;
                this.GetStates();    
                this.GetAndSetCompany();
                this.GetAllCompanies();
                this.showCompanyNameError = 0;
                this.showGstin = 0;
                this.showCaption = 0;
                this.showCessAndDealer = 0;
              }
              else{
                this.isCompanyFailure = 1;
              }
            }, (err: Error) => {
              console.log(err.message);
            });
        }
      }
    }
    catch (ex) {
      this.logService.InsertExceptionLog(ex,"Add Company","SaveNewCompany");
      console.log("Error in SaveNewCompany : " + ex);
    }
  }

  ResetExistValue() {
    this.isCompanyExist = 0;
  }

  ResetSuccessValue() {
    this.isCompanySucess = 0;
  }

  ResetFailureValue(){
    this.isCompanyFailure = 0;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'F2') {
      this.SaveNewCompany();
    } else if (event.key === 'Enter') {
      console.log('enter is pressed')
    } else if (event.key === 'Tab') {
      console.log("tab is pressed");
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeyDown(event: KeyboardEvent) {
    this.redirectToHome();
  }
}
