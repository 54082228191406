<div class="navbar row col-8 col-sm-6 col-md-4 col-lg-12 justify-content-left align-content-start align-items-lg-center ms-0 me-0">

  <div class="nav-header row col-12 col-lg-2 ms-0 align-items-center pt-4 pt-lg-0">
      <!-- <img src="../../assets/images/logo/logo.png" alt="" id="logo" class="col-8 col-md-8 " [ngClass]="{'navbar-open': isNavbarOpen}"> -->
      <div id="logo" class="row col-12 col-xl-11 col-xxl-10 pt-4 pt-lg-0">
          <img (click)="redirectTo('landing-page')" src="../../../../../assets/images/logo.png" alt="" id="logo" class="col-8 col-lg-12 align-self-left"
          >
      </div>
  </div>
</div>
<div class="row main-div ms-2 me-2">
    <mat-vertical-stepper #stepper [linear]="true">
    <mat-step label="Pricing" [completed]="isPricingCompleted">
    <div id="pricing" class="row ms-2 me-2 pt-5">
        <div class="pricing-cards row col-12 ms-auto me-auto justify-content-start gap-2 ms-sm-3">
          <div class="pricing-card col-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 me-sm-4" *ngFor="let package of packageDetails">
            <div class="card-content">
                <p class="col-12 fw-bold mt-3 mb-3">{{package.packageName}}</p>
                <div class="price">
                    <div *ngIf="!isPaymentCompleted" class="initial-price mb-3">
                        <div class="initial-price-heading">
                            <p>For 1st year</p>
                        </div>
                    </div>
                    <div class="initial-price mb-3">
                        <div class="initial-price-amount row align-items-baseline">
                            <i class="fas fa-rupee-sign col-1"></i>
                            <p *ngIf="!isPaymentCompleted" class="col-4 fw-bold">{{package.price}}</p>
                            <p *ngIf="isPaymentCompleted" class="col-4 fw-bold">{{package.amcPrice}}</p>
                        </div>
                        <div *ngIf="!isPaymentCompleted" class="final-price-heading">
                            <p>From 2nd year</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isPaymentCompleted" class="company-limit mb-1">
                    <div class="final-price-amount row align-items-baseline">
                        <i class="fas fa-rupee-sign col-1"></i>
                        <p class="col-10">{{package.amcPrice}} per year</p>
                    </div>
                </div>
                <div *ngIf="!isPaymentCompleted" class="company-limit mb-3">
                    <p>10 Days trial</p>
                </div>
                <button class="primary-button ms-0" style="cursor: pointer;" (click)="nextStep(stepper,package)" >Choose Plan</button>
            </div>
          </div>
        </div>
    </div>
    </mat-step>

    <mat-step label="Payment">
      <div id="payment" class="row ms-2 me-2">
        <div
          class="payment-info order-sm-1 row col-12  col-md-7 col-lg-8 col-xl-6 mt-4 mt-sm-0 pt-4 ms-auto me-auto ms-sm-0 ms-xl-3 mt-xl-5" >
          <img src="../../../../../assets/images/payment/smallCircle.png" alt="" id="small-circle">
          <img src="../../../../../assets/images/payment/mediumCircle.png" alt="" id="medium-circle-payment">
          <div id="payment-info-gradient"></div>
          <h4 class="col-12 mb-4 fw-bold">Enter payment info to start your subscription</h4>
          <div class="sign-in-info row col-12">
            <p class="fw-bold col-12 mb-2">Signed in as</p>
            <p class="col-12">{{subscriberEmail}} <span routerLink="/home"
                style="color:var(--oranges--orange-600);"></span></p>
          </div>
          <hr class="row col-12 ms-0">
          <div class="user-info row col-12 ms-2 pb-5" [formGroup]="subscriberFormGroup">
            <div class="row col-12 ps-0 pe-0 justify-content-between">
              <input type="text" formControlName="firstName" placeholder="First name" class="col-12 col-md-5 mb-4">
              <input type="text" formControlName="lastName" placeholder="Last name" class="col-12 col-md-5 mb-4">
            </div>
            <div class="row col-12 ps-0 pe-0">
              <input type="text" formControlName="billingAddress" placeholder="Billing address" class="col-12 mb-4">
            </div>
            <div class="row col-12 ps-0 pe-0 justify-content-between">
              <input type="text" formControlName="city" placeholder="City" class="col-12 col-md-5 mb-4">
              <input type="text" formControlName="state" placeholder="State/Province" class="col-12 col-md-5 mb-4">
            </div>
            <div class="region row col-12 ps-0 pe-0 justify-content-between">
              <input type="text" formControlName="pinCode" placeholder="Pin code" class="col-12 col-md-5 mb-4">
              <div class="col-12 col-md-5 mb-4 ps-0 pe-0">
                <p class="col-12 pt-0 pb-0 mb-0 ps-md-1">Country/Region</p>
                <select formControlName="country" class="col-12">
                  <option value="India">India</option>
                </select>
              </div>
            </div>

            <div class="optional row col-12 ps-0 pe-0 pe-md-3 justify-content-between">
              <label for="city" class="ps-0 col-12 fw-bold">Optional</label>
              <div class="row col-12 col-md-5 align-items-baseline mb-4 ms-0 ps-0">
                <input type="text" formControlName="phoneNumber" name="" id="" placeholder="Phone Number" class="col-10 col-md-10">
                <i class="fas fa-info-circle col-1 ps-1"></i>
              </div>
            </div>
            <div class="col-12 p-0 agree-and-subscribe-container">
              <input type="checkbox" (click)="toggleAgree()" >
              <p class="col-12 ps-0">By clicking “Agree and Subscribe”, you agree:
                <span id="charged" class="fw-bold">
                  You will be charged Rs 6000 (Incl GST) yearly. Your subscription will automatically renew
                  annually until you cancel (price subject to change). Cancel anytime via
                </span>
                <span class="orange fw-bold">
                  Account Settings > Billing section or Customer Support
                </span>
                . You also agree to the&nbsp;
                <span class="orange">
                  Terms of Use and Subscription
                </span>
                &nbsp;
                and the &nbsp;
                <span class="orange">
                  Cancellation Terms
                </span>
                .
              </p>
            </div>
            <button
              class="order-button col-10 col-sm-5 col-md-6 col-lg-4 text-center pt-1 pb-1 ps-2 pe-2" (click)="onPaymentClick(stepper)">Agree and subscribe</button>
          </div>
        </div>

      </div>
    </mat-step>
    <mat-step label="Summary">
      <div class="summary order-sm-2 row ms-auto me-auto ms-sm-0 me-sm-0 me-xl-3 mt-xl-5 pt-3 pb-2" [ngClass]="{'summary-open': isSummary}"
       *ngIf="selectedPackage != undefined">
          <img src="../../../../../assets/images/payment/mediumCircle.png" alt="" id="medium-circle-summary">
          <div id="summary-gradient"></div>
          <h4 class="col-12 fw-bold">Summary</h4>
          <hr>
          <h5 class="fw-semibold col-12">{{selectedPackage.packageName}} Plan</h5>
          <div class="row col-12 justify-content-between">
            <p class="col-6">Subscription</p>
            <p class="row col-6 align-items-center">
              <i class="fas fa-rupee-sign col-1"></i>
              <span class="fw-semibold col-6">{{selectedPackage.price}}/yr</span>
            </p>
          </div>           

            <p class="col-12 fw-semibold">Yearly</p>
            <hr>

            <div class="row col-12 justify-content-between">
              <p class="col-6">Subtotal</p>
              <p class="row col-6 align-items-center">
                <i class="fas fa-rupee-sign col-1"></i>
                <span class="fw-semibold col-6">{{selectedPackage.price}}/yr</span>
              </p>
            </div>
            <div class="row col-12 justify-content-between">
              <div class="row col-6">
                <p class="col-8">GST 18%</p>
                <i class="fas fa-info-circle col-1"></i>
              </div>
              <p class="row col-6 align-items-center">
                <i class="fas fa-rupee-sign col-1"></i>
                <span class="fw-semibold col-6 me-0">{{ selectedPackage?.gstPrice }}/yr</span>
              </p>
            </div>

            <div class="row col-12 justify-content-between">
              <p class="col-6">Total</p>
              <p class="row col-6 align-items-center">
                <i class="fas fa-rupee-sign col-1 fw-semibold"></i>
                <span class="fw-semibold col-6">{{ selectedPackage.totalPrice }}</span>
              </p>
            </div>
            <button class="primary-button col-11 mt-2 mb-4 me-4" (click)="redirectToPayNow()">Pay Now</button>
          
        </div>
    </mat-step>
    </mat-vertical-stepper>
</div>
<app-web-footer></app-web-footer>
