<div class="main-div">
  <form class="container" [formGroup]="enquiryform">
    <div class="content">
      <div class="img-container">
        <img src="../../../../../assets/images/enquiry/mockups.png" alt="">
      </div>

    </div>

    <div class="form-container">
      <h3>Get Your Custom Software Solution Today!</h3>
      <div class="two-col-content">
        <div class="field-set">
          <label for="fullname">Fullname*</label>
          <input type="text" name="" id="" formControlName="FullName" >
        </div>
        <div class="field-set">
          <label for="mobilenumber">Mobile Number*</label>
          <input type="text" [maxLength]="10" name="" id="" formControlName="MobileNumber" (blur)="verifyMobileNumber()">
          <span *ngIf="mobileNumber?.invalid && mobileNumber?.touched" style="color: red;">Invalid Mobile Number!</span>
        </div>
      </div>
      <div class="field-set">
        <label for="email">Email*</label>
        <input type="text" name="" id="" formControlName="Email" >
      </div>
      <div class="field-set">
        <label for="requirements">Requirements*</label>
        <textarea name="" id="" cols="30" rows="9" formControlName="Requirements" ></textarea>
      </div>
      <div class="field-set" *ngIf="isOTPSent && !isOTPVerified">
        <label for="otp">Enter OTP*</label>
        <input type="number" name="" id="" formControlName="OTP" >
      </div>
      <button type="button" (click)="sendOTP()" *ngIf="!isOTPSent && isMobileVerified">Send OTP</button>
      <button type="button" (click)="verifyOTP()" *ngIf="isOTPSent && !isOTPVerified">Verify OTP</button>
      <button type="submit" (click)="save()" [disabled]="!isOTPVerified">Submit</button>
    </div>
  </form>
  <div class="section">
    <div class="left-section">
      <h2>We deliver quality ERP software solutions.</h2>
      <p>We are a company which can create a web application using Angular and Dot Net Core. Actually very recently we
        have developed a single codebase, cross-platform web application using Angular and Typescript and we have
        created API using .Net Core as a back-end technology.
      </p>
      <p>
        For database purpose, we have used dapper and MSSQL. We
        have also created an android app using the same codebase with Capacitor by Ionic.
      </p>
    </div>
    <div class="right-section">
      <h3>Our milestones</h3>
      <div class="metrics-container">
        <div class="metric-card">
          <h1>10+ </h1>
          <p>Years experience</p>
        </div>
        <div class="metric-card">
          <h1>600+ </h1>
          <p>Satisfied clients</p>
        </div>
        <div class="metric-card">
          <h1>10+ </h1>
          <p>Products</p>
        </div>
      </div>
      <div class="USP-container">
        <h3>Customized software solutions</h3>
        <h6>
          Export report in tally
        </h6>
        <h6>
          Generate unique barcodes
        </h6>
        <h6>
          Send invoices through whatsapp, sms and email
        </h6>
      </div>
    </div>
  </div>
</div>
