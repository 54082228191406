import { Component, OnInit, ElementRef } from '@angular/core';
import Swiper from 'swiper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})

export class AboutUsComponent implements OnInit {
  constructor(private elementRef: ElementRef,private router: Router) {}

  ngOnInit() {

    const swiper = new Swiper('.testimonial-cards', {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      pagination: {
        el: '.swiper-pagination',
      },
      loop: true,
    });
  }

  redirectTo(page){
    this.router.navigate([page]);
  }
}
