<app-web-navbar></app-web-navbar>

<div class="main-div row col-12 col-lg-8 mt-4 mb-4 ms-auto me-auto justify-content-center">
    <!-- <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="IsNewSubscription">
        <strong>Registration Successfull !!</strong>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
            (click)="ResetNewSubscription()"></button>
    </div> -->

    <div class="subscriber-login-header row col-12 col-lg-6 ms-0 d-none d-lg-flex order-2">
        <div class="stairs-top-left">
            <img src="../../assets/images/subscriber-login/stair-top-left.png" alt="">
        </div>

        <div class="subscriber-login-content row col-12 mt-4 ">
            <div class="subscriber-login-img mb-5 col-lg-7 ms-auto me-auto">
                <img src="../../assets/images/subscriber-login/subscriber-login-img.png" alt="">
            </div>
            <div class="subscriber-login-description row col-12 col-lg-8 ms-auto me-auto mb-4">
                <h3 class="col-12 text-center">Take control of your business today!</h3>
                <p class="col-12 text-center"> Track new sales, invoices, and important transactions. Take control of your business today!</p>
            </div>
        </div>

        <div class="stairs-bottom-right">
            <img src="../../assets/images/subscriber-login/stair-bottom-right.png" alt="">
        </div>
    </div>

    <div class="subscriber-login row col-12 col-sm-8 col-md-6 col-lg-6 mt-4 me-lg-1 ps-2 pe-0 justify-content-center order-1">
        <form class="row col-12" [formGroup]="subscriberLoginGroup"
            (ngSubmit)="Userlogin()">
            <div class="company-logo col-4">
                <img src="../../assets/images/logo/logo.png" alt="" class="col-12">
            </div>
            <div class="form-heading row col-12 mt-3 mb-3">
                <h3 class="col-12 fw-bold">Login</h3>
                <p class="col-12 fw-bolder">See your growth and get sales reports</p>
            </div>
            <div class="google-login mb-3 pt-2 pb-2" id="google-button" *ngIf="!platform.is('capacitor')"></div>
            <div class="google-login mb-3 pt-2 pb-2 ps-3" id="google-button" (click)="signInWithGoogle()" *ngIf="platform.is('capacitor')">
              <img src="../../../assets/images/subscriber-login/android_neutral_rd_SI.svg" alt="">
            </div>
            <div class="sign-in-or row col-12 mb-3 align-items-center justify-content-center">
                <hr class="">
                <div class="">
                    <p> or Sign in with Email</p>
                </div>
                <hr class="">
            </div>

            <div class="email-div row col-12">
                <label for="email" class="col-12 col-lg-5 ms-0 mb-2">Email*</label>
                <mat-form-field appearance="outline">
                    <input matInput class=" mb-3 pt-1 pb-1" placeholder="pat@example.com" formControlName="Username" required>
                    <mat-error *ngIf="Username.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>

                <!-- <span class="col-12" *ngIf="Username.invalid">{{getErrorMessage()}}</span> -->


                <label for="password" class="col-12 col-lg-5 ms-0 mb-2">Password*</label>
                <mat-form-field appearance="outline">
                    <input matInput class=" mb-3 pt-1 pb-1" [type]="hide ? 'password' : 'text'" formControlName="Password">
                    <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon color="warn">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>

                </mat-form-field>
            </div>

            <div class="options row col-12 mb-3 align-items-center">
                <div class="remember row col-6 align-items-center">
                    <input type="checkbox" class="col-1" formControlName="rememberMe">
                    <label for="checkbox" class="col-11 pe-0 fw-bold">Remember me</label>
                </div>
                <a class="col-6 text-end fw-bold pe-0" (click)="redirectToForgotPassword()">Forgot Password?</a>
            </div>
            <div class="row col-12 mb-3 align-items-center">
                <ngx-recaptcha2 #captchaElem [siteKey]="CaptchaSiteKey" [size]="size" (success)="handleSuccess($event)" [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha">
                </ngx-recaptcha2>
            </div>
            <div class="login-button row col-12 mb-3">
                <button class="ps-2 pe-2 pt-1 pb-1 text-center primary-button" type="submit">Login</button>

                <p style="text-align: center;margin-top: 4px;cursor: pointer;" *ngIf="InvalidLogin">
                    <span style="color: red;">Invalid username or password</span>
                </p>
            </div>

            <div class="create-account row col-12 mb-3">
                <p class="fw-bold col-6 pe-0">Not registered yet?</p>
                <a (click)="redirectToSignup()" class="fw-bold col-6 ps-0 text-end">Create an Account</a>

                <span style="color: red;">{{ErrorMessage}}</span>
            </div>
        </form>
    </div>
</div>

<app-web-footer></app-web-footer>
