import { Directive, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
  
  @Directive({
    selector: '[readonly],[readOnly]',
    host: {
      '[attr.readonly]': '_isReadonly ? "" : null'
    }
  })
  export class ReadonlyDirective {
    _isReadonly = false;
  
    @Input() set readonly (v) {
       this._isReadonly = coerceBooleanProperty(v);
    };
  
    ngOnChanges(changes) {
      console.log(changes);
    }
  }