import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { LogService } from 'src/app/services/common/log.service';
import { SubscriberService } from 'src/app/services/subscriber.service';

@Component({
  selector: 'app-subscriber-forgot-password',
  templateUrl: './subscriber-forgot-password.component.html',
  styleUrls: ['./subscriber-forgot-password.component.css']
})
export class SubscriberForgotPasswordComponent implements OnInit {

  IsUsername = 1;
  firstFormGroup : FormGroup;
  secondFormGroup : FormGroup;
  thirdFormGroup : FormGroup;

  IsEmailValid = false;
  IsSuccessOTP = true;
  IsOTPValid = false;
  IsPasswordNotMatch = false;

  hide = true;
  confirmhide = true;

  constructor(private router:Router,
    private subscriberService:SubscriberService,
    private _formBuilder: FormBuilder,
    private logService:LogService){}

  ngOnInit(): void {
    try{
      this.firstFormGroup = this._formBuilder.group({
        EmailID: ['', Validators.required],
      });
      this.secondFormGroup = this._formBuilder.group({
        EmailID: [''],
        OTP: ['', Validators.required],
      });
      this.thirdFormGroup = this._formBuilder.group({
        EmailID: [''],
        Password: ['', Validators.required],
        ConfirmPassword: ['', Validators.required]
      });
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Subscriber Forgot Password","ngOnInit");
      console.log(ex);
    }    
  }

  ResetPassword(stepper : MatStepper){
    try{
      this.IsEmailValid = false;
      if(this.firstFormGroup.valid){
        this.subscriberService.SetOtpAndEmailForSubscriber(this.firstFormGroup.value).subscribe(
          (result) => {
            if(result.status > 0 && result.isSuccess == 1){
              stepper.next();
            }
            else{
              this.IsEmailValid = true;
            }
          }, (err: Error) => {
            console.log(err.message);
          })
      }
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Subscriber Forgot Password","ResetPassword");
      console.log(ex);
    }    
  }

  ValidateOTP(stepper : MatStepper){
    try{      
      this.secondFormGroup.value.EmailID = this.firstFormGroup.value.EmailID;
      if(this.secondFormGroup.valid){
        this.subscriberService.ValidateOTPForSubscriber(this.secondFormGroup.value).subscribe(
          (result) => {
            if(result.status > 0 && result.isSuccess == 1){
              stepper.next();
            }
            else{
              this.IsOTPValid = true;
            }
          }, (err: Error) => {
            console.log(err.message);
          })
      }
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Subscriber Forgot Password","ValidateOTP");
      console.log(ex);
    }
  }

  ResetSavePassword(stepper : MatStepper){
    try{
      if(this.thirdFormGroup.value.Password != this.thirdFormGroup.value.ConfirmPassword){
        this.IsPasswordNotMatch = true;
      }
      else{
        this.IsPasswordNotMatch = false;
        this.thirdFormGroup.value.EmailID = this.firstFormGroup.value.EmailID;
        if(this.thirdFormGroup.valid){
          this.subscriberService.UpdateSubscriberPassword(this.thirdFormGroup.value).subscribe(
            (result) => {
              if(result.status > 0 && result.isSuccess == 1){
                stepper.next();
              }
              else{
                console.log('reset password failed');
              }
            }, (err: Error) => {
              console.log(err.message);
            })
        }
      }
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Subscriber Forgot Password","ResetSavePassword");
      console.log(ex);
    }
  }

  ResetIsSuccessOTP(){
    this.IsSuccessOTP = false;
  }

  RedirectToLogin(){
    this.router.navigate(
      ['subscriber-login']
    ); 
  }  
}
