import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { Platform } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { SubscriberLoginResp } from 'src/app/components/interface/Subscriber/SubscriberLoginResp';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformGuard implements CanActivate {
  subscriberString: string;

  constructor(private platform: Platform, private router: Router, private authService: AuthService, private cookieService: CookieService) {}

  async canActivate(): Promise<boolean> {
    if (this.platform.is('capacitor')) {
      const subscriber = await this.authService.getPreferenceSubscriberDetails();
      if (subscriber) {
        this.router.navigate(['view-company'], { queryParams: { subscriberID: subscriber.subscriberID } });
        return true;
      } else {
        this.router.navigate(['subscriber-login']);
        return false;
      }
    } else {
      this.subscriberString = sessionStorage.getItem('subscriber');
      if (!this.subscriberString) {
        this.subscriberString = this.cookieService.get('subscriber');
      }

      if(this.subscriberString) {
        let subscriberResp: SubscriberLoginResp = this.subscriberString ? JSON.parse(this.subscriberString) : null;

        this.router.navigate(['view-company'], { queryParams: { subscriberID: subscriberResp.subscriberID } });
        return true;
      } 
      else {
        // this.router.navigate(['landing-page']);
        return false;
      }
    }
  }
}
