<app-web-navbar></app-web-navbar>

<div class="main-div row col-12 col-sm-8 col-md-12  mt-5">
    <div class="hero row col-12 col-md-10 mb-5  justify-content-md-center align-items-center">
        <div class="hero-content row col-12 col-md-6 mb-3 align-content-center">
            <h3>Find a better solution for your business</h3>
            <p class="fw-bold">We specialize in customizing software for different sectors, meeting specific client
                needs and enhancing productivity.</p>
            <a routerLink="" class="col-6 pt-1 pb-1 text-center">Explore more</a>
        </div>
        <div class="hero-img col-md-6">
            <img src="../../../../../assets/images/about/heroImage.svg" alt="" class="col-12">
        </div>
    </div>
    <div  class="about-company row col-12  col-md-10  justify-content-md-center mb-5">
        <div class="about-company-content row col-12 col-md-6 mb-3 order-md-2 align-content-center">
            <h3>Who we are?</h3>
            <p class="fw-bold">ASquare ERP Solutions Pvt. Ltd. provides software solutions for micro, small and medium
                retailers as well as to manufacturers. We have been in this industry for more than 5 years with more
                than 600+ installations</p>
            <a routerLink="" class="col-6 pt-1 pb-1 text-center"> Explore more</a>
        </div>
        <div class="about-company-img row col-md-6">
            <img src="../../../../../assets/images/about/about-company.png" class="col-12" alt="">
        </div>

    </div>
    <div class="counter-cards row col-12 col-md-10 col-lg-8 ms-md-auto me-md-auto mt-5 mb-5  justify-content-center">
        <div class="counter-card row col-4 justify-content-center">
            <i class="fas fa-clock text-baseline p-3 mb-2"></i>
            <h4 class="col-10 text-center mb-2 counter">5+</h4>
            <p class="col-10 text-center">Years</p>
        </div>

        <div class="counter-card row col-4 justify-content-center">
            <i class="fas fa-medal text-baseline p-3 mb-2"></i>
            <h4 class="col-10 text-center mb-2 counter">500+</h4>
            <p class="col-10 text-center">Clients</p>
        </div>

        <div class="counter-card row col-4 justify-content-center">
            <i class="fas fa-arrow-down text-baseline p-3 mb-2"></i>
            <h4 class="col-10 text-center mb-2 counter">600+</h4>
            <p class="col-10 text-center">Installations</p>
        </div>
    </div>

    <script src="http://cdnjs.cloudflare.com/ajax/libs/waypoints/2.0.3/waypoints.min.js"></script>
<script src="jquery.counterup.min.js"></script>

    <div class="services row col-12 col-md-10  justify-content-md-center ms-md-auto me-md-auto mb-5">
        <div class="services-header row col-12 mb-4">
            <div class="services-header-content row col-12 col-md-6 align-content-center justify-content-center text-center">
                <h3>We provide solutions for</h3>
                <p class="fw-bold">We specialize in customizing software for different sectors, meeting specific client
                    needs and enhancing
                    productivity.</p>
                <a routerLink="" class="col-6 pt-1 pb-1 text-center">View demo</a>
            </div>
            <div class="services-header-img row col-12 col-md-6 justify-content-center">
                <img src="../../../../../assets/images/about/services-image.svg" alt="" class="col-12">
            </div>
        </div>

        <div class="services-content row col-12 mt-4 mb-4 ms-md-4 justify-content-center">
            <h3 class="col-12 mb-5">We provide solutions for</h3>
            <div class="services-content-card row col-12">
                <h2 class="fw-bold col-4">01</h2>
                <i class="fas fa-tshirt col-3 mb-2"></i>
                <h4 class="fw-bold">Whole sale</h4>
                <p class="fw-bolder">
                    • Increase Sales <br>
                    • Get Sales report of Item in demand <br>
                    • Expiry Date for Item <br>
                    • Unsold Stock <br>
                    • Credit Limit <br>
                    • Pending Amount during live bill. <br>
                    • SMS For Outstanding Payments. <br>
                    • Unit conversion on Purchase and Sale.

                </p>
            </div>

            <div class="services-content-card row col-12 mb-4">
                <h2 class="fw-bold col-4">02</h2>
                <i class="fas fa-bread-slice col-3 mb-2"></i>
                <h4 class="fw-bold">Retail</h4>
                <p class="fw-bolder"> • Multiple Barcode while Billing <br>
                    • Automatic and Manual Barcode No. <br>
                    • Item wise Profit <br>
                    • Point system by Mobile Number <br>
                    • Fixed and Variable point Redeem System <br>
                    • Mobile No. wise Billing <br>
                    • Multiple Payment Mode. <br>
                    • Mulitple Window Billing</p>
            </div>

            <div class="services-content-card row col-12 mb-4">
                <h2 class="fw-bold col-4">03</h2>
                <i class="fas fa-cogs col-3 mb-2"></i>
                <h4 class="fw-bold">Manufacturing</h4>
                <p class="fw-bolder">• Purchase Order to Delivery Challan <br>
                    • Bill of Material <br>
                    • Production Order <br>
                    • Issue Slip <br>
                    • GRN Report <br>
                    • Tracking Management System <br>
                    • Department wise Modules. <br>
                    • Convert Quotation / Order / Challan to Invoice.</p>
            </div>

            <div class="services-content-card row col-12">
                <h2 class="fw-bold col-4">04</h2>
                <i class="fas fa-utensils col-3 mb-2"></i>
                <h4 class="fw-bold">Restaurant</h4>
                <p class="fw-bolder">• Easy to understand Table System <br>
                    • Multiple Kot for Single Table <br> 
                    • Convert Multiple Kots to Single Invoice <br>
                    • Category wise and Sub Category wise Sales Report <br> 
                    • Home Delivery and Parcel system <br>
                    • Customizable Bill Format <br>
                    • Easy to use Billing screen <br>
                    • Multiple Stage Recipe configuration</p>
            </div>
        </div>

    </div>

    <div class="team row col-12 col-md-10  justify-content-md-center ms-md-auto me-md-auto mb-5">
        <div class="team-header row col-12 mb-3 text-md-center">
            <h3>Become a team member</h3>
        </div>
        <div class="team-cards row col-12 gap-5 justify-content-md-center">
            <div class="team-card row col-12 col-md-5 col-lg-3 col-xl-2 ps-2 pe-2 pt-3 pb-3 mb-4 ms-2 rounded">
                <img src="../../../../../assets/images/about/user-profile-icon.jpg" alt="" class="mb-2">
                <h5>Aditya kshatriya</h5>
                <p class="fw-bolder">(Director)</p>
                <p>B.E. (MIT Pune)</p>
            </div>
            
            <div class="team-card row col-12 col-md-5 col-lg-3 col-xl-2 ps-2 pe-2 pt-3 pb-3 mb-4 ms-2 rounded">
                <img src="../../../../../assets/images/about/user-profile-icon.jpg" alt="" class="mb-2">
                <h5>Abhishek kshatriya</h5>
                <p class="fw-bolder">(Consultant)</p>
                <p>Chartered Accountant</p>
            </div>

            <div class="team-card row col-12 col-md-5 col-lg-3 col-xl-2 ps-2 pe-2 pt-3 pb-3 mb-4 ms-2 rounded">
                <img src="../../../../../assets/images/about/user-profile-icon.jpg" alt="" class="mb-2">
                <h5>Mukund kshatriya</h5>
                <p class="fw-bolder">(Consultant)</p>
                <p>Chartered Accountant</p>
            </div>
        </div>

        <a routerLink="" class="col-6 col-md-4 ms-md-auto me-md-auto pt-1 pb-1 text-center">Join now</a>
    </div>

    <div class="testimonials row col-12 col-md-10  justify-content-md-center ms-md-auto me-md-auto mb-5 justify-content-md-center">
        <div class="testimonials-header row col-12 mb-3 text-md-center">
            <h3>Hear from our clients</h3>
        </div>
        <div class="swiper-container testimonial-cards col-12 col-sm-8 col-md-5 overflow-hidden pt-4 pb-4">
            <div class="swiper-wrapper">
                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5>Shreeji Electronics</h5>
                            <hr>
                            <p>Sinnar</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>The software is very reasonable with all the necessary features in it. Service is very prompt. Till date they have provided me with all the required customisations unlike other companies.</p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>
    
                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5>Kavya Collection</h5>
                            <hr>
                            <p>Nasik</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>"Whenever we call the company for any support we have been fully satisfied. Staff is very soft spoken. One of the features that I like about this software is its multiple billing window."</p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>
    
                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5>Rane Dairy</h5>
                            <hr>
                            <p>Nasik</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>"I was finding software which will suit the seeds of my dairy shop. Finally I came across A-Square software. They provided me the software will all the necessary customisations which are required in dairy business which are hard to find in other software."</p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>

                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5>Chandwadkar Garments</h5>
                            <hr>
                            <p>Nasik</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>"I had used many billing Softwares for my retail shop but my father was not happy. So I decided to buy a new one which will solve my business needs. This is when I came to know about A-Square ERP. Very detailed software at a very cheap cost"</p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>

                <div class="testimonial-card swiper-slide ps-3 pe-3 rounded ms-4 me-4">
                    <div class="testimonial-card-header row col-12 pt-3 mb-4 ms-0">
                        <div class="testimonial-card-header-img col-4 align-self-center">
                            <img src="../../../../../assets/images/about/3dcircle.svg" alt="">
                        </div>
                        <div class="testimonial-card-header-heading col-8">
                            <h5>Tara Telecom</h5>
                            <hr>
                            <p>Ozar</p>
                        </div>
                    </div>
                    <div class="testimonial-card-content mb-3">
                        <i class="fas fa-quote-left col-12"></i>
                        <p>"This is a very easy software to use will all the features. I always recommend my friends to use this software whenever they ask me for suggestion."</p>
                        <i class="fas fa-quote-right col-12 text-end"></i>
                    </div>
                </div>
                <!-- Add more testimonial-card slides here if needed -->
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>

    <div class="product-cta row col-12 col-md-10  justify-content-md-center ms-md-auto me-md-auto">
        <div class="row col-12 mb-3 text-md-center">
            <h4>Start your free trial</h4>
            <p class="fw-bold">Join over 500+ businesses already growing with A-square Erp Solutions</p>
        </div>
        <a (click)="redirectTo('subscriber-signup')" class="col-7 col-md-4 col-lg-3 ms-auto me-auto me-md-3 me-2 pt-1 pb-1 mb-2 mb-md-0 text-center">Get started</a>
        
        <a routerLink="" class="view-demo-button col-7 col-md-4 col-lg-3 ms-auto me-auto  ms-md-3 pt-1 pb-1 text-center">View demo</a>
    </div>
</div>

<app-web-footer></app-web-footer>
