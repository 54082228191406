import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriberDetails } from 'src/app/modules/admin/interface/SubscriberDetails';
import Swal from 'sweetalert2';
import { Blog } from '../../interface/Blog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { LogService } from 'src/app/services/common/log.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.css']
})
export class ViewBlogComponent {

  constructor(private router: Router,
    private authService: AuthService,
    private blogService: BlogService,
    private route: ActivatedRoute,
    private cookieService:CookieService,
    private logService:LogService,
    private subscriberService:SubscriberService) { }

  dataSource = new MatTableDataSource<Blog>();
  displayedColumns: string[] = ['srNo', 'author', 'category', 'description' ,'actions'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayStyle = "none";
  allBlog: any;
  userID:number;

  isErrorMessage = 0;

  hide = true;

  ngOnInit(): void {
    this.userID = +sessionStorage.getItem('UserID')
    this.GetAllBlog()
  }

  // For Paginator and Sorting
  ngNewpaginator() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  GetAllBlog() {
    try {
      this.allBlog = [];
      let user = { UserID: this.userID }
      this.blogService.GetAllBlog(user).subscribe(
        (result: Blog[]) => {
            result.forEach((element, index) => {
              element.srNo = index + 1
            });

            this.allBlog = result;
            console.log(this.allBlog)
          this.dataSource = new MatTableDataSource<Blog>(this.allBlog);
          this.ngNewpaginator();
        }, (err: Error) => {
          console.log(err.message);
        });
    }
    catch (ex) {
      this.logService.InsertExceptionLog(ex,"View Blog","GetAllBlog");
      console.log(ex);
    }
  }

  deleteBlog(blogID) {
    this.blogService.DeleteBlog(blogID).subscribe(
      () => {
        this.GetAllBlog();
      },
      (err: any) => {
        console.log(err.message);
      }
    );
    this.displayStyle = "block";
  }

  updateBlog(Blog: Blog) {
    this.router.navigate(
      ['add-blog'],
      { queryParams: { BlogId: Blog.blogID } });
  }

  addBlog() {
    this.router.navigate(
      ['add-blog']);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['subscriber-login']);
  }
}
