import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { BlogLoginResp } from 'src/app/components/interface/Blog/BlogLoginResp';
import { CompanyLoginResp } from 'src/app/components/interface/Company/CompanyLoginResp';
import { SubscriberLoginResp } from 'src/app/components/interface/Subscriber/SubscriberLoginResp';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {

  constructor(private cookieService: CookieService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const blogUserString = sessionStorage.getItem('blogUser');
    const companyUserString = sessionStorage.getItem('companyUser');
    let subscriberString = sessionStorage.getItem('subscriber');

    if(!subscriberString) {
      subscriberString = this.cookieService.get('subscriber');
    }

    if(blogUserString) {
      const user: BlogLoginResp = JSON.parse(blogUserString);
      request = request.clone({
        setHeaders:{
          Authorization : `Bearer ${user.token}`
        }
      });
    } else if(companyUserString) {
      const user: CompanyLoginResp = JSON.parse(companyUserString);
      request = request.clone({
        setHeaders:{
          Authorization : `Bearer ${user.token}`
        }
      });
    } else if(subscriberString) {
      const user: SubscriberLoginResp = JSON.parse(subscriberString);
      request = request.clone({
        setHeaders:{
          Authorization : `Bearer ${user.token}`
        }
      });
    }
    
    // let tokenizedReq = request.clone({
    //   setHeaders:{
    //     Authorization : `Bearer ${this.authService.getToken()}`
    //   }
    // });

    return next.handle(request);
  }
}
