import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from 'src/app/services/common/encryption.service';
import { LogService } from 'src/app/services/common/log.service';
import Swal from "sweetalert2";
import { BlogService } from 'src/app/services/blog.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-blog-login',
  templateUrl: './blog-login.component.html',
  styleUrls: ['./blog-login.component.css']
})
export class BlogLoginComponent {

  ErrorMessage: string;
  subscriberLoginGroup: FormGroup;
  googleGroup: FormGroup;
  hide = true;
  Username = new FormControl('', [Validators.required, Validators.email]);
  selectedValue: string;
  signUpSubscriberID = "";
  loginSubscriberID = "";

  IsNewSubscription = 0;
  InvalidLogin = 0;
  IsPaymentCompleted = 0;

  reCaptchaVerfication = null;

  CaptchaSiteKey = environment.recaptcha.siteKey;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';

  constructor(private authService: AuthService,
     private router: Router, private route: ActivatedRoute,
     private cookieService: CookieService,
     private encrypt: EncryptionService,
     private logService: LogService) {}

  ngOnInit(): void {
    try{
      // var id = sessionStorage.getItem("UserId");
      // if(id == null || id == undefined){
      //   var username = this.cookieService.get('Username');
      //   var password = this.cookieService.get('Password');

      //   if (username != "" && password != "") {
      //     this.subscriberLoginGroup = new FormGroup({
      //       Username: new FormControl(this.encrypt.decryptionAES(username)),
      //       Password: new FormControl(this.encrypt.decryptionAES(password))
      //     });
      //     this.Userlogin(1);
      //   }
      //   else{
           this.initForm();
      //   }
      // }
      // else{
      //   this.router.navigate(
      //     ['view-company'],
      //     { queryParams: { UserId: sessionStorage.getItem("UserId") } }
      //   )
      // }
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Blog Login","ngOnInit");
      console.log(ex);
    }
  }

  initForm() {
    try{
      this.subscriberLoginGroup = new FormGroup({
        Username: new FormControl("", [Validators.required]),
        Password: new FormControl("", [Validators.required]),

      });
      this.reCaptchaVerfication = null;
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Blog Login","initForm");
      console.log(ex);
    }
  }

  Userlogin(IsCookieLogin?) {
    try {
      this.InvalidLogin = 0;

      if(this.reCaptchaVerfication == null && IsCookieLogin == undefined) {
        alert("Please tick I am not a robot!");
      }
      else {
        if (this.subscriberLoginGroup.valid) {
          this.authService.blogLogin(this.subscriberLoginGroup.value).subscribe(
            (result) => {
              if(result.success){
                //sessionStorage.setItem("UserId",result.UserId);
                this.initForm();

                this.router.navigate(
                  ['view-blog'],
                  { queryParams: { UserId: result.userID } }
                );
              }
              else{
                this.InvalidLogin = 1;
              }
            }, (err: Error) => {
              console.log(err.message);
          });
        }
      }
    } catch (ex) {
      this.logService.InsertExceptionLog(ex,"Blog Login","Userlogin");
      console.log(ex);
    }
  }

  handleSuccess(data) {
    this.reCaptchaVerfication = data;
  }

  getErrorMessage() {
    if (this.Username.hasError('required')) {
      return 'You must enter a value';
    }

    return this.Username.hasError('Username') ? 'Not a valid Username' : '';
  }
}
