import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigSMSService {

  constructor(private router: Router, private http: HttpClient) { }

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  GetAllConfigSMS(): Observable<any> {
    const body = new HttpParams({ });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}configSMS/GetAllConfigSMS`, body, options);
  }

  GetConfigSMSByID(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}configSMS/GetConfigSMSByID`, body, options);
  }

  InsertConfigSMS(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}configSMS/InsertConfigSMS`, body, options);
  }

  UpdateConfigSMS(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}configSMS/UpdateConfigSMS`, body, options);
  }

  DeleteConfigSMS(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}configSMS/DeleteConfigSMS`, body, options);
  }

  SendSMS(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}configSMS/SendSMS`, body, options);
  }
}

