<div class="container">
  <div class="col-md-12">
    <div class="error-template">
      <div class="img-container">
        <img src="../../../assets/images/not-found/notFoundMountains.svg" alt="">
      </div>
      <div class="error-actions">
        <a href="/login" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>Take Me Home </a>
      </div>
    </div>
  </div>
</div>
