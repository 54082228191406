import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private router: Router, private http: HttpClient) { }

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });
  

  GetCompanyByID(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Company/GetCompanyByID`, body, options);
  }

  GetStates(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Company/GetStatesForNewCompany`, body, options);
  }

  GetAllCompanies(data): Observable<any> {
    const body = new HttpParams({fromObject:data});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Company/GetAllCompanies`, body, options);
  }

  GetCompanyByCompanyID(): Observable<any> {
    const body = new HttpParams({});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Company/GetCompanyByCompanyID`, body, options);
  }

  InsertCompany(data): Observable<any> {
    const body = new HttpParams({fromObject:data});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Company/InsertCompany`, body, options);
  }

  UpdateCompany(data): Observable<any> {
    const body = new HttpParams({fromObject:data});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Company/UpdateCompany`, body, options);
  }

  ResetPasswordForCompany(data): Observable<any> {
    const body = new HttpParams({fromObject:data});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Company/ResetPasswordForCompany`, body, options);
  }

  DeleteCompany(data): Observable<any> {
    const body = new HttpParams({fromObject:data});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Company/DeleteCompany`, body, options);
  }

  DeleteCompanyByFY(data): Observable<any> {
    const body = new HttpParams({fromObject:data});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Company/DeleteCompanyByFY`, body, options);
  }
}
