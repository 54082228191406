<div class="main-div" [formGroup]="blogGroup">
    <div class="col-sm-12 field">
        <div class="row">
            <div class="col-sm-2 label-form">
                <label>Upload Image</label>
            </div>
            <div class="col-sm-10">
                <input type="file" accept="image/*" class="form-control form-control-sm" (change)="onFileSelected($event)">
            </div>
        </div>
    </div>
    <div class="col-sm-12 field">
        <div class="row">
            <div class="col-sm-2 label-form">
                <label>Author</label>
            </div>
            <div class="col-sm-10">
                <input type="text" formControlName="author" class="form-control form-control-sm">
            </div>
        </div>
    </div>
    <div class="col-sm-12 field">
        <div class="row">
            <div class="col-sm-2 label-form">
                <label>Category</label>
            </div>
            <div class="col-sm-10">
                <input type="text" formControlName="category" class="form-control form-control-sm">
            </div>
        </div>
    </div>
    <div class="col-sm-12 field">
        <div class="row">
            <div class="col-sm-2 label-form">
                <label>Description</label>
            </div>
            <div class="col-sm-10">
                <input type="text" formControlName="description" class="form-control form-control-sm">
            </div>
        </div>
    </div>
    <div class="col-sm-10">
        <div class="editor">
            <angular-editor formControlName="blogContent"></angular-editor>
        </div>
        <!-- [(ngModel)]="editorData" -->
        <!-- app.component.html -->

    </div>
    <div>
        <button (click)="submit()">Submit</button>
        <button (click)="close()">Close</button>
    </div>
</div>