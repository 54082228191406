<app-web-navbar></app-web-navbar>

<div class="terms-container">

    <h1 class=" page-heading mb-1">Welcome to ASquareCloudClient!</h1>
    <div class="decorator mb-4"></div>
    <p class="mb-3">Last updated on April 1 2024  </p>
    
    
    <p>These terms and conditions outline the rules and regulations for the use of A-Square ERP Solutions's Website, located at <span class="ghost-link"> https://app.asquareerp.com/. </span></p>
    
    <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use <span class="fw-semibold"> ASquareCloudClient </span> if you do not agree to take all of the terms and conditions stated on this page.</p>
    
    <p>For the purpose of these Terms and Conditions, the term <span class="fw-semibold"> "we","us","our" </span> used anywhere on this page shall mean A-SQUARE ERP SOLUTIONS PRIVATE LIMITED, whose registered operational office is 22, Sahajeevan Colony, College Road Nashik MAHARASTRA 422005. <span class="fw-bold"> "you","your","user","visitor" </span> shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.  </p>
    
    <h3 class="fw-bold">Your use of the website and/or purchase from us are governed by following Terms and Conditions:</h3>
    
    <ul>
      <li>The content of the pages of this website is subject to change without notice.</li>
      <li> Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and material found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain innaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.  </li>
      <li>Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
      <li>Your use of any information or materials on our website and/or product page is entirely at your own risk, for which we shall not be liable. It shall be our responsibility to ensure that any products, services or information available through our website and/or pages </li>
      <li>All trademarks reproduced in our website which are not the property of or licensed to, the operator are acknowledged on the website.</li>
      <li>Unauthorized use of information provided by us shall give rise to a claim for damages and/or a criminal offense.</li>
      <li>From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.</li>
    </ul>
    
    
    <h2 class="fw-bold mb-1">Terms and Conditions</h2>
    <div class="decorator mb-4"></div>
    
    <ul>
      <li>You may not create a link to our website from another website or document without <span class="fw-semibold"> A-SQUARE ERP SOLUTIONS PRIVATE LIMITED'S </span> prior written consent.</li>
      <li>Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India.</li>
      <li>We shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time</li>
      <li>Redistribute content from <span class="fw-semibold"> ASquareCloudClient </span> </li>
    </ul>
    
   
    
    
    
    <h3 class="fw-bold mb-1">Cookies</h3>
    <div class="decorator mb-4"></div>
    
    <p>We employ the use of cookies. By accessing <span class="fw-semibold"> ASquareCloudClient </span>, you agreed to use cookies in agreement with the A-Square ERP Solutions's Privacy Policy. </p>
    
    <p>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
    
    <h3 class="fw-bold mb-1">License</h3>
    <div class="decorator mb-4"></div>
    
    <p>Unless otherwise stated, A-Square ERP Solutions and/or its licensors own the intellectual property rights for all material on <span class="fw-semibold"> ASquareCloudClient </span>. All intellectual property rights are reserved. You may access this from <span class="fw-semibold"> ASquareCloudClient </span> for your own personal use subjected to restrictions set in these terms and conditions.</p>
    
    <p class="list-headings">You must not:</p>
    <ul>
        <li>Republish material from <span class="fw-semibold"> ASquareCloudClient </span></li>
        <li>Sell, rent or sub-license material from <span class="fw-semibold"> ASquareCloudClient </span></li>
        <li>Reproduce, duplicate or copy material from <span class="fw-semibold"> ASquareCloudClient </span></li>
        <li>Redistribute content from <span class="fw-semibold"> ASquareCloudClient </span></li>
    </ul>
    
    <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the <a href="https://www.termsandconditionsgenerator.com/">Free Terms and Conditions Generator</a>.</p>
    
    <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. A-Square ERP Solutions does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of A-Square ERP Solutions,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, A-Square ERP Solutions shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
    
    <p>A-Square ERP Solutions reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
    
    <p class="list-headings">You warrant and represent that:</p>
    
    <ul>
        <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
        <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
        <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
        <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
    </ul>
    
    <p>You hereby grant A-Square ERP Solutions a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>
    
    <h3 class="fw-bold mb-1">Hyperlinking to our Content</h3>
    <div class="decorator mb-4"></div>
    
    <p class="list-headings">The following organizations may link to our Website without prior written approval:</p>
    
    <ul>
        <li>Government agencies;</li>
        <li>Search engines;</li>
        <li>News organizations;</li>
        <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
        <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
    </ul>
    
    <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>
    
    <p class="list-headings">We may consider and approve other link requests from the following types of organizations:</p>
    
    <ul>
        <li>commonly-known consumer and/or business information sources;</li>
        <li>dot.com community sites;</li>
        <li>associations or other groups representing charities;</li>
        <li>online directory distributors;</li>
        <li>internet portals;</li>
        <li>accounting, law and consulting firms; and</li>
        <li>educational institutions and trade associations.</li>
    </ul>
    
    <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of A-Square ERP Solutions; and (d) the link is in the context of general resource information.</p>
    
    <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</p>
    
    <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to A-Square ERP Solutions. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
    
    <p class="list-headings">Approved organizations may hyperlink to our Website as follows:</p>
    
    <ul>
        <li>By use of our corporate name; or</li>
        <li>By use of the uniform resource locator being linked to; or</li>
        <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
    </ul>
    
    <p>No use of A-Square ERP Solutions's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
    
    <h3 class="fw-bold mb-1">iFrames</h3>
    <div class="decorator mb-4"></div>
    
    <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
    
    <h3 class="fw-bold mb-1">Content Liability</h3>
    <div class="decorator mb-4"></div>
    
    <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
    
    <h3 class="fw-bold mb-1">Reservation of Rights</h3>
    <div class="decorator mb-4"></div>
    
    <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
    
    <h3 class="fw-bold mb-1">Removal of links from our website</h3>
    <div class="decorator mb-4"></div>
    
    <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
    
    <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
    
    <h3 class="fw-bold mb-1">Disclaimer</h3>
    <div class="decorator mb-4"></div>
    
    <p class="list-headings">To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
    
    <ul>
        <li>limit or exclude our or your liability for death or personal injury;</li>
        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
        <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
    </ul>
    
    <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>
    
    <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
    </div>

    <app-web-footer></app-web-footer>
    