<div class="page-heading ps-3">
    <h2 class="fw-bold pt-3">Add Company</h2>
</div>
<div class="main-div pt-3" [formGroup]="company">
        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="isCompanyExist">
            Company <strong>{{company.value.Name}}</strong> is already exists.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                (click)="ResetExistValue()"></button>
        </div>
        <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="isCompanySucess">
            Company has been added sucessfully.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                (click)="ResetSuccessValue()"></button>
        </div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="isCompanyFailure">
            Company has not added sucessfully.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                (click)="ResetFailureValue()"></button>
        </div>
        <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="isCompanyUpdate">
            Company has been updated sucessfully.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                (click)="ResetSuccessValue()"></button>
        </div>
        <div class="field-sets ps-3 pe-3 pb-3 pt-3 pb-3 rounded">
            <div class="row col-12 ">
                <div class="row col-md-6 mb-3 pe-0 pe-md-3">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label class="required-field">Name*</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10 ps-2 ps-md-0 pe-0">
                            <input type="text" formControlName="Name" class="col-12 ">
                            <span *ngIf="showCompanyNameError" style="color:var(--oranges--orange-600)">**Please Enter Name..</span>
                        </div>
                </div>
                <div class="row col-md-6 mb-3 pe-0 pe-md-0">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>Street Line 1</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10 ps-md-0 pe-0 pe-md-0">
                            <input type="text" formControlName="StreetLine1" class="col-12 ">
                        </div>
                </div>
            </div>
            <div class="row col-12  ">
                <div class="row col-md-6 mb-3 pe-0 pe-md-3">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>Street Line 2</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10  ps-md-0 pe-0">
                            <input type="text" formControlName="StreetLine2" class="col-12 ">
                        </div>
                </div>
                <div class="row col-md-6 mb-3 pe-0 pe-md-0">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>City</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10 ps-md-0 pe-0 pe-md-0">
                            <input type="text" formControlName="City" class="col-12 ">
                        </div>
                </div>
            </div>
            <div class="row col-12  ">
                <div class="row col-md-6 mb-3 pe-0 pe-md-3">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>State</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10  ps-md-0 pe-0">
                            <ng-select placeholder="Select State.." formControlName="State">
                                <ng-option [value]="state.key" *ngFor="let state of states">
                                    {{state.value}}
                                </ng-option>
                            </ng-select>
                        </div>
                </div>
                <div class="row col-md-6 mb-3 pe-0 pe-md-0">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>Pin Number</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10 ps-md-0 pe-0 pe-md-0">
                            <input type="text" formControlName="PinNumber" class="col-12 ">
                        </div>
                </div>
            </div>
            <div class="row col-12  ">
                <div class="row col-md-6 mb-3 pe-0 pe-md-3">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>Phone No.</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10  ps-md-0 pe-0">
                            <input type="text" formControlName="PhoneNumber" class="col-12 "
                                >
                        </div>
                </div>
                <div class="row col-md-6 mb-3 pe-0 pe-md-0">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>Mobile No.</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10 ps-md-0 pe-0 pe-md-0">
                            <input type="text" formControlName="MobileNumber" class="col-12 ">
                        </div>
                </div>
            </div>
            <div class="row col-12  ">
                <div class="row col-md-6 mb-3 pe-0 pe-md-3">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>Email</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10  ps-md-0 pe-0">
                            <input type="text" formControlName="Email" class="col-12 "
                                >
                        </div>
                </div>
                <div class="row col-md-6 mb-3 pe-0 pe-md-0">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>Fax</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10 ps-md-0 pe-0 pe-md-0">
                            <input type="text" formControlName="Fax" class="col-12 ">
                        </div>
                </div>
            </div>
            <div class="row col-12  ">
                <div class="row col-md-6 mb-3 pe-0 pe-md-3">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>PanNo</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10  ps-md-0 pe-0">
                            <input type="text" formControlName="PanNumber" class="col-12 ">
                        </div>
                </div>
                <div class="row col-md-6  pe-0 pe-md-0">
                        <div class="col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>FY Start Date</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10 ps-md-0 pe-0 pe-md-0">
                            <input type="text" formControlName="FyStartDate" class="col-12 "
                                placeholder="dd/MM/yyyy" (focus)="SetDate('')" (blur)="ConvertToDate('')">
                        </div>
                </div>
            </div>
        </div>
        <div class="tax-div field-sets ps-3 pe-3 pt-3 mt-3 pb-3 rounded">
            <h4 class="fw-bold" >GST Information</h4>
            <div class="col-12 field row">
                <div class="row col-md-6 mb-2 pe-0">
                        <div class="col-12 col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>Enable GST</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10  pe-0 pe-md-4 ps-md-0">
                            <select formControlName="EnableGST" class="col-12 "
                                (change)="ShowCessAndDealer()">
                                <option value="N">N</option>
                                <option value="Y">Y</option>
                            </select>
                        </div>
                </div>
                <div class="row col-md-6 mb-2 pe-0 pe-md-0" *ngIf="showCessAndDealer">
                        <div class="col-12 col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 pe-0 label-form">
                            <label>Enable Cess</label>
                        </div>
                        <div class="col-12  col-md-8 col-lg-9 col-xxl-10 row ps-4 ps-md-2 pe-0 pe-md-2">
                            <div class="col-3 col-md-3 ps-0 ps-md-1">
                                <select formControlName="EnableCess" class="col-12 "
                                    (change)="ShowCaption()">
                                    <option value="N">N</option>
                                    <option value="Y">Y</option>
                                </select>
                            </div>
                            <div class="col-9 col-md-9 pe-0" *ngIf="showCaption">
                                <input type="text" formControlName="Caption" placeholder="Caption" class="col-12  mt-md-0">
                            </div>
                        </div>
                </div>
            </div>
            <div class="col-12 field row">
                <div class="row col-md-6 mb-2 pe-0 pe-md-3" *ngIf="showCessAndDealer">
                        <div class="col-12 col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0 label-form">
                            <label>Type Of Dealer</label>
                        </div>
                        <div class="col-md-8 col-lg-9 col-xxl-10  pe-0 pe-md-2 ps-md-1">
                            <ng-select formControlName="TypeOfDealer" (change)="ShowGstin()" class="col-12">
                                <ng-option value="">Select Dealer</ng-option>
                                <ng-option value="Composite">Composite</ng-option>
                                <ng-option value="Regular">Regular</ng-option>
                            </ng-select>
                        </div>
                </div>
                <div class="row col-md-6 mb-2 pe-0" *ngIf="showGstin">
                        <div class="col-12 col-md-4 col-lg-3 col-xxl-2 pb-1 pb-md-0  label-form">
                            <label>GSTIN</label>
                        </div>
                        <div class="col-12 col-md-8 col-lg-9 col-xxl-10 pe-2 ps-md-0 pe-md-2">
                            <input type="text" formControlName="GSTIN" class="col-12 ">
                        </div>
                </div>
            </div>
        </div>
        <div>
            <span *ngIf="isLimitReachedErr" style="color:var(--oranges--orange-600);">Allocated company credit is over. For adding more companies, Please Upgrade Your Package.</span>
        </div>
    <div class="row ps-3 mt-3 pt-3 pb-3 buttons-container" style="
    background: var(--black-and-white--white);
">
            <button class="primary-button col-5" (click)="SaveNewCompany()">Save</button>
            <button class="secondary-button col-5" (click)="redirectToHome()">Close</button>
    </div>
</div>