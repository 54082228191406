import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private http: HttpClient) {
    this.destroy$ = new Subject<boolean>();
   }

  logFromGroup : FormGroup;
  public destroy$: Subject<boolean>;

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  InsertExceptionLog(exception,className,methodName){
    this.logFromGroup = new FormGroup({
      Exception : new FormControl(exception),
      ClassName : new FormControl(className),
      MethodName : new FormControl(methodName),
      Sitename : new FormControl(location.href),
      Level : new FormControl('TsComponent')
    });

    this.InsertExceptionLogWebAPI(this.logFromGroup.value)
    .pipe(takeUntil(this.destroy$)).subscribe((value: any) => console.log(value));
  }

  InsertExceptionLogWebAPI(data): Observable<any>{
    const body = new HttpParams({ fromObject : data});
    const options = {headers : this.headers};
    return this.http.post(`${environment.ASquareAPIURL}Log/InsertExceptionLogAngular`, body, options);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
