<div class="main-div" [ngClass]="{'blur-background' : displayStyle === 'block'}">
  <div class="">
    <div class="mobile-heading">
      <h3 class="fw-bold mb-0">Blog List</h3>
    </div>
    <div class="top-nav">
      <div class="desktop-heading">
        <h3 class="fw-bold">Blog List</h3>
      </div>
      <div></div>
      <div class="top-nav-menu-item-container">
        <div class="top-nav-menu-item">
          <i class="fas fa-plus"></i>
          <span (click)="addBlog()">New</span>
        </div>

        <div class="top-nav-menu-item">
          <i class="fas fa-sign-out-alt"></i>
          <span (click)="logout()">LogOut</span>
        </div>
      </div>
    </div>
  
    <mat-divider></mat-divider>
  
    <div class="col-12 col-sm-5 col-lg-4 row search_filter ms-2 ms-sm-3 mt-3 mb-3">
      <input class="pt-1 pb-1" placeholder="search blog" (keyup)="applyFilter($event)" #input>
    </div>
  </div>
  
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="srno" matSortDirection="asc">
      <!-- srno Column -->
      <ng-container matColumnDef="srNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-md-table-cell">Sr. No.</th>
        <td mat-cell *matCellDef="let element" class="d-block d-md-table-cell mb-3">
          <span class="td-before col-12" mat-sort-header>Sr. No</span>
          <span class="col-12 d-flex">
            {{element.srNo}}
          </span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-md-table-cell">Author</th>
        <td mat-cell *matCellDef="let element" class="d-block d-md-table-cell mb-3">
          <span class="td-before col-12">Author</span>
          <span class="col-12 d-flex">{{element.author}} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-md-table-cell">Category</th>
          <td mat-cell *matCellDef="let element" class="d-block d-md-table-cell mb-3">
            <span class="td-before col-12">Category</span>
            <span class="col-12 d-flex">{{element.category}} </span>
          </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-md-table-cell">Description</th>
          <td mat-cell *matCellDef="let element" class="d-block d-md-table-cell mb-3">
            <span class="td-before col-12">Description</span>
            <span class="col-12 d-flex">{{element.description}} </span>
          </td>
      </ng-container>
  
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell">Actions</th>
          <td mat-cell *matCellDef="let row" class="d-block d-md-table-cell mb-3">

            <i class="fas fa-edit col-1 col-md-2 fs-6" (click)="updateBlog(row)"
              style="color:var(--oranges--orange-600);"></i>

            <i class="fas fa-trash col-1 col-md-2 ps-1 fs-6" (click)="deleteBlog(row)"
              style="color:var(--oranges--orange-600);" aria-label="delete"></i>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow style="display: center;">
        <td class="mat-cell mb-4" [attr.colspan]="displayedColumns.length">
          No data matching the filter.
        </td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>

  
