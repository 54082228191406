import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountInWords'
})
export class AmountInWordsPipe implements PipeTransform {

  units = [ "Zero", "One", "Two", "Three",
    "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven",
    "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
    "Seventeen", "Eighteen", "Nineteen" ];
  tens = [ "", "", "Twenty", "Thirty", "Forty",
    "Fifty", "Sixty", "Seventy", "Eighty", "Ninety" ];

  transform(amount: number) {
    try {
      let amount_int = Math.trunc(amount);
      let amount_dec = Math.round((amount - (amount_int)) * 100);
      if (amount_dec == 0) {
        return this.Convert(amount_int) + " Only.";
      }
      else {
        return this.Convert(amount_int) + " Point " + this.Convert(amount_dec) + " Only.";
      }
    }
    catch (Exception) {
      // TODO: handle exception  
    }
    return "";
  }

  Convert(i) {
    if (i < 20) {
      return this.units[i];
    }
    if (i < 100) {
      return this.tens[Math.trunc(i / 10)] + ((i % 10 > 0) ? " " + this.Convert(i % 10) : "");
    }
    if (i < 1000) {
      let pos = Math.trunc(i / 100);
      let rem = i % 100;
      return this.units[pos] + " Hundred"
        + ((rem > 0) ? " And " + this.Convert(rem) : "");
    }
    if (i < 100000) {
      return this.Convert(i / 1000) + " Thousand "
        + ((i % 1000 > 0) ? " " + this.Convert(i % 1000) : "");
    }
    if (i < 10000000) {
      return this.Convert(i / 100000) + " Lakh "
        + ((i % 100000 > 0) ? " " + this.Convert(i % 100000) : "");
    }
    if (i < 1000000000) {
      return this.Convert(i / 10000000) + " Crore "
        + ((i % 10000000 > 0) ? " " + this.Convert(i % 10000000) : "");
    }
    return this.Convert(i / 1000000000) + " Arab "
      + ((i % 1000000000 > 0) ? " " + this.Convert(i % 1000000000) : "");
  }
}

  // a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  // b = ['', '', 'Twenty', 'Thirty', 'Fourty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninty'];

  // transform(value: string): any {
  //   if ((value = value.toString()).length > 9) return 'overflow';
  //       let n = ('000000000' + value).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  //       if (!n) return; let str = '';
  //       str += (Number(n[1]) != 0) ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'Crore ' : '';
  //       str += (Number(n[2]) != 0) ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'Lakh ' : '';
  //       str += (Number(n[3]) != 0) ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'Thousand ' : '';
  //       str += (Number(n[4]) != 0) ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'Hundred ' : '';
  //       str += (Number(n[5]) != 0) ? ((str != '') ? 'And ' : '') + (this.a[Number(n[5])] || this.b[n[5][0]] + ' ' + this.a[n[5][1]]) : '';
  //       str += ' Rupees Only';
  //       return str;
  // }

