<app-web-navbar></app-web-navbar>

<div class="main-div row ms-auto me-auto col-10">
    <div class="pricing-header col-12 text-center">
        <h3>Get Started for <span>Free</span></h3>
        <p>Enhance collaboration and boost productivity with our powerful billing system.</p>
        <a (click)="redirectTo('contact-us')">Contact Us</a>
    </div>

    <div class="pricing-cards row col-10 col-sm-12 ms-auto me-auto justify-content-start gap-2 ps-sm-5">
        <div class="pricing-card col-12 col-sm-5 col-md-3 col-lg-3 col-xl-2 me-sm-4 ">
            <div class="card-content">
                <p class="col-12 fw-bold mt-3 mb-3">Basic</p>
                <div class="price">
                    <div class="initial-price mb-3">
                        <div class="initial-price-heading">
                            <p>For 1st year</p>
                        </div>
                    </div>
                    <div class="initial-price mb-3">
                        <div class="initial-price-amount row align-items-baseline">
                            <i class="fas fa-rupee-sign col-1"></i>
                            <p class="col-4 fw-bold">6000</p>
                        </div>
                        <div class="final-price-heading">
                            <p>From 2nd year</p>
                        </div>
                    </div>
                </div>
                <div class="company-limit mb-1">
                    <div class="final-price-amount row align-items-baseline">
                        <i class="fas fa-rupee-sign col-1"></i>
                        <p class="col-10">2000 per year</p>
                    </div>
                </div>
                <div class="company-limit mb-3">
                    <p>10 Days trial</p>
                </div>
                <a (click)="redirectTo('subscriber-signup')" class="">Try it for free</a>
                <p class="reason mt-3 mb-3">For individuals to try out the system</p>
                <hr class="price-content-division mb-3">
                <div class="service-description">
                    <p class="mb-3">Upto two Companies</p>
                    <div class="service-description-cards">
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Manage Clients</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Manage Sales Invoices</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">E-mail Invoices</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Delivery Challan</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Manage Sales Orders</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Manage Quotations</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Credit Notes</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Customer Payment</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Account Statement</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Outstanding Reports</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pricing-card col-12 col-sm-5 col-md-3 col-lg-3 col-xl-2 me-md-4">
            <div class="card-content">
                <p class="col-12 fw-bold mt-3 mb-3">Silver</p>
                <div class="price">
                    <div class="initial-price mb-3">
                        <!-- <div class="initial-price-amount row align-items-baseline mb-2">
                            <i class="fas fa-rupee-sign col-1"></i>
                            <h3 class="col-8">0</h3>
                        </div> -->
                        <div class="initial-price-heading">
                            <p>For 1st year</p>
                        </div>
                    </div>
                    <div class="initial-price mb-3">
                        <div class="initial-price-amount row align-items-baseline">
                            <i class="fas fa-rupee-sign col-1"></i>
                            <p class="col-4 fw-bold">12000</p>
                        </div>
                        <div class="final-price-heading">
                            <p>From 2nd year</p>
                        </div>
                    </div>
                </div>
                <div class="company-limit mb-1">
                    <div class="final-price-amount row align-items-baseline">
                        <i class="fas fa-rupee-sign col-1"></i>
                        <p class="col-10">4000 per year</p>
                    </div>
                </div>
                <div class="company-limit mb-3">
                    <p>10 Days trial</p>
                </div>
                <a (click)="redirectTo('subscriber-signup')" class="">Try it for free</a>
                <p class="reason mt-3 mb-3">For individuals to try out the system</p>
                <hr class="price-content-division mb-3">
                <div class="service-description">
                    <p class="mb-3">Upto two Companies</p>
                    <p class="mb-3 fw-bolder feature-sub-heading">Includes Everything from Basic plan</p>
                    <div class="service-description-cards">
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Manage Purchase Invoices</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Manage Purchase Orders</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Delivery Challan</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Debit Notes</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Supplier Payment</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Stock Reports</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Balance Sheet</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Profit & Loss Report</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pricing-card col-12 col-sm-5 col-md-3 col-lg-3 col-xl-2 me-sm-4">
            <div class="card-content">
                <p class="col-12 fw-bold mt-3 mb-3">Gold</p>
                <div class="price">
                    <div class="initial-price mb-3">
                        <!-- <div class="initial-price-amount row align-items-baseline mb-2">
                            <i class="fas fa-rupee-sign col-1"></i>
                            <h3 class="col-8">0</h3>
                        </div> -->
                        <div class="initial-price-heading">
                            <p>For 1st year</p>
                        </div>
                    </div>
                    <div class="initial-price mb-3">
                        <div class="initial-price-amount row align-items-baseline">
                            <i class="fas fa-rupee-sign col-1"></i>
                            <p class="col-4 fw-bold">15000</p>
                        </div>
                        <div class="final-price-heading">
                            <p>From 2nd year</p>
                        </div>
                    </div>
                </div>
                <div class="company-limit mb-1">
                    <div class="final-price-amount row align-items-baseline">
                        <i class="fas fa-rupee-sign col-1"></i>
                        <p class="col-10">5000 per year</p>
                    </div>
                </div>
                <div class="company-limit mb-3">
                    <p>10 Days trial</p>
                </div>
                <a (click)="redirectTo('subscriber-signup')" class="">Try it for free</a>
                <p class="reason mt-3 mb-3">For individuals to try out the system</p>
                <hr class="price-content-division mb-3">
                <div class="service-description">
                    <p class="mb-3">Upto three Companies</p>
                    <p class="mb-3 fw-bolder feature-sub-heading">Includes Everything from Silver plan</p>
                    <div class="service-description-cards">
                        <div class="service-description-card row mb-1">
                            <p class="col-8">SMS Module</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Barcode Printing</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Serial Number Tracking</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">GSTR-1 Reports</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">GSTR-2 Reports</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">GSTR-3B Report</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">E-Invoicing & E-Way Bill</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Tracking Orders and Challans</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pricing-card col-12 col-sm-5 col-md-3 col-lg-3 col-xl-2 me-md-4">
            <div class="card-content">
                <p class="col-12 fw-bold mt-3 mb-3">Platinum</p>
                <div class="price">
                    <div class="initial-price mb-3">
                        <!-- <div class="initial-price-amount row align-items-baseline mb-2">
                            <i class="fas fa-rupee-sign col-1"></i>
                            <h3 class="col-8">0</h3>
                        </div> -->
                        <div class="initial-price-heading">
                            <p>For 1st year</p>
                        </div>
                    </div>
                    <div class="initial-price mb-3">
                        <div class="initial-price-amount row align-items-baseline">
                            <i class="fas fa-rupee-sign col-1"></i>
                            <p class="col-4 fw-bold">20000</p>
                        </div>
                        <div class="final-price-heading">
                            <p>From 2nd year</p>
                        </div>
                    </div>
                </div>
                <div class="company-limit mb-1">
                    <div class="final-price-amount row align-items-baseline">
                        <i class="fas fa-rupee-sign col-1"></i>
                        <p class="col-10">7000 per year</p>
                    </div>
                </div>
                <div class="company-limit mb-3">
                    <p>10 Days trial</p>
                </div>
                <a (click)="redirectTo('subscriber-signup')" class="">Try it for free</a>
                <p class="reason mt-3 mb-3">For individuals to try out the system</p>
                <hr class="price-content-division mb-3">
                <div class="service-description">
                    <p class="mb-3">Unlimited Companies</p>
                    <p class="mb-3 fw-bolder feature-sub-heading">Includes Everything from Gold plan</p>
                    <div class="service-description-cards">
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Bill of Material</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Assemble from BOM</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Unassemble from BOM</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">By Product Tracking</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Multi-Location Tracking</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                        <div class="service-description-card row mb-1">
                            <p class="col-8">Unlimited Customized Reports</p>
                            <i class="fas fa-info-circle col-2"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pricing-card col-12 col-sm-5 col-md-3 col-lg-3 col-xl-2">
            <div class="card-content">
                <p class="col-12 fw-bold mt-3 mb-3">Custom</p>
                <div class="price">
                    <div class="row initial-price mb-3">
                        <!-- <div class="initial-price-amount row align-items-baseline mb-2">
                            <i class="fas fa-rupee-sign col-1"></i>
                            <h3 class="col-8">0</h3>
                        </div> -->
                        <div class="col-md-12 col-lg-10 col-xl-12 col-xxl-10 initial-price-description">
                            <p>Contact us to get tailor-made plans best suited for your organization.</p> <br>
                        </div>
                    </div>
                    <div class="final-price mb-2">
                        <div class="final-price-amount row align-items-baseline">
                            <!-- <i class="fas fa-rupee-sign col-1"></i> -->
                            <p class="col-4">&nbsp;</p>
                        </div>
                        <div class="final-price-description">
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
                <div class="company-limit mb-1">
                    <div class="final-price-amount row align-items-baseline">
                        <!-- <i class="fas fa-rupee-sign col-1"></i> -->
                        <!-- <p class="col-6">&nbsp;</p> -->
                    </div>
                </div>
                <div class="company-limit mb-2">
                    <!-- <p>&nbsp;</p> -->
                </div>
                <a (click)="redirectTo('contact-us')" class="">Contact Us</a>
                <p class="reason mt-3 mb-3">For individuals to try out the system</p>
                <hr class="price-content-division mb-3">
                <div class="service-description">
                    <p class="mb-3">At A-Square ERP Solutions, we understand that every organization is unique, with specific needs and requirements. <br> <br> That's why we offer a custom pricing plan tailored to suit your organization perfectly. <br> <br>  With our personalized approach, you can be confident that you'll get precisely what you need to achieve your goals efficiently and effectively.</p>
                    <p>Experience the power of a personalized pricing plan. Contact us now.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-web-footer></app-web-footer>
