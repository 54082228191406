import { Directive, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appDynamicDimensions]'
})
export class DynamicDimensionsDirective implements AfterViewInit, OnDestroy {

  private observer: MutationObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.adjustWidth();
    this.observeContentChanges();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private adjustWidth() {
    const parentElement = this.el.nativeElement;
    const children = parentElement.querySelectorAll('li');

    let maxWidth = parentElement.clientWidth; // Initialize with the width of the parent element
    const basePadding = 8; // Assuming 8px padding for each depth level

    children.forEach((child: Element) => {
      const depth = this.getDepth(child);
      const childWidth = child.scrollWidth + (depth * basePadding);
      if (childWidth > maxWidth) {
        maxWidth = childWidth;
      }
    });

    this.renderer.setStyle(parentElement, 'width', `${maxWidth}px`);
  }

  private getDepth(element: Element): number {
    let depth = 0;
    while (element.parentElement && element.parentElement !== this.el.nativeElement) {
      if (element.parentElement.tagName === 'UL') {
        depth++;
      }
      element = element.parentElement;
    }
    return depth;
  }

  private observeContentChanges() {
    this.observer = new MutationObserver(() => {
      this.adjustWidth();
    });

    this.observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
    });
  }
}
