import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigSMS } from 'src/app/modules/admin/interface/ConfigSMS';
import { SMSDetails } from 'src/app/modules/admin/interface/SMSDetails';
import { ConfigSMSService } from 'src/app/services/config-sms.service';
import { EnquiryService } from 'src/app/services/enquiry.service';
import { SmsTemplateService } from 'src/app/services/sms-template.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit{

  constructor(
    private enquiryService: EnquiryService,
    private router: Router,
    private smsTemplateService: SmsTemplateService,
    private configSMSService: ConfigSMSService
  ) { }

  enquiryform!: FormGroup;

  FullName:string = '';
  MobileNumber:string = '';
  enquiry_id:number = 0;
  Requirements:string=''
  Email:string=''

  isOTPSent: boolean = false;
  isOTPVerified: boolean = false;
  isMobileVerified: boolean = false;
  smsTemplate = [];
  smsAPI = [];

  smsDetails: SMSDetails = {
    vchId: '',
    vchType: '',
    templateId: '',
    ledgerId: '',
    mobileNo: '',
    apiId: ''
  };

  smsConfig: ConfigSMS = {
    srNo: 0,
    smsApiId: '',
    name: '',
    smsApi: '',
    mobileParameter: '',
    messageParameter: '',
    characterLength: 0,
    removeLineFeed: false
  }

  enquiryData={
    enquiry_id:0,
    full_name:'',
    mobile_number:'',
    requirements:'',
    enquiry_email:'',
  }

    ngOnInit(): void {
      this.getAllSMSAPI();
      this.getAllSMSTemplates();
      this.GetEnquiry();
    }

    GetEnquiry() {
      this.enquiryform = new FormGroup({
        enquiry_id: new FormControl(),
        FullName: new FormControl(),
        MobileNumber: new FormControl('', Validators.pattern(/^\d{10}$/)),
        Requirements: new FormControl(),
        Email: new FormControl(),
        OTP: new FormControl()
      });
    }

    get mobileNumber() {
      return this.enquiryform.get('MobileNumber');
    }

    getAllSMSTemplates() {
      try {
        this.smsTemplateService.GetSmsTemplate().subscribe(
          (result) => {
            this.smsTemplate = result;
          },
          (err: Error) => {
            console.log(err.message);
          }
        );
      } catch (ex) {
        console.log(ex);
      }
    }
  
    getAllSMSAPI() {
      try {
        this.configSMSService.GetAllConfigSMS().subscribe(
          (result) => {
            this.smsAPI = result;
          },
          (err: Error) => {
            console.log(err.message);
          }
        );
      } catch (ex) {
        console.log(ex);
      }
    }

    verifyMobileNumber() {
      if(this.mobileNumber?.valid && this.mobileNumber?.value !== '') {
        this.isMobileVerified = true;
      } else {
        this.isMobileVerified = false;
      }
    }

  sendOTP(){
    let mobileNumber = +this.enquiryform.controls['MobileNumber'].value;
    if (mobileNumber.toString().length == 10) {
      try {
        let data = {
          contact: mobileNumber
        };

        this.enquiryService.SendOTP(data).subscribe(
          (result) => {

            this.isOTPSent = true;
          },
          (err: Error) => {
            console.log(err.message);
            this.isOTPSent = false;
          });
      } catch (ex) {
        console.log(ex);
        this.isOTPSent = false;
      }
    }
  }

  verifyOTP(){
    let mobileNumber = this.enquiryform.controls['MobileNumber'].value;
    let otp = this.enquiryform.controls['OTP'].value;
    if (mobileNumber && otp) {
      try {
        let data = {
          contact: mobileNumber,
          otp: otp
        };

        this.enquiryService.VerifyOTP(data).subscribe(
          (result) => {

            this.isOTPVerified = true;
          },
          (err: Error) => {
            console.log(err.message);
            this.isOTPVerified = false;
          });
      } catch (ex) {
        console.log(ex);
        this.isOTPVerified = false;
      }
    }
  }

  save(){

    this.enquiryData={
      enquiry_id:this.enquiryform.value.enquiry_id,
      full_name:this.enquiryform.value.FullName,
      mobile_number:this.enquiryform.value.MobileNumber,
      requirements:this.enquiryform.value.Requirements,
      enquiry_email:this.enquiryform.value.Email,
    }

    // if(this.vehicle_id !== 0)
    //   {
    //     this.enquiryService.UpdateVehicle(this.enquiryData).subscribe(
    //       (data) => {
    //         this.router.navigate(
    //           ['admin/vehicle/view']);
    //       },
    //       (err: any) => {
    //         console.log(err.message);
    //       });
    //   }
    //   else
    //   {
        this.enquiryService.InsertEnquiry(this.enquiryData).subscribe(
          (data) => {

          },
          (error: any) => {
            console.log(error);
          }
        );
      // }
  }


}
