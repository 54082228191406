import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { Blog } from '../../interface/Blog';
import { LogService } from 'src/app/services/common/log.service';

@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.css']
})
export class AddBlogComponent implements OnInit{

  description:string;
  author: string;
  category: string;
  blogContent: string;
  HtmlBody: string;
  image: string;
  BlogID: number = 0;

  blogGroup: FormGroup;
  blog:Blog;

  constructor(private blogService: BlogService,
    private router: Router, private route: ActivatedRoute, private logService:LogService,){}

  ngOnInit(): void {

    this.route.queryParamMap.subscribe(params => {
      if (params.has('BlogId')) {
        this.BlogID = +params.get('BlogId');
      }
    })

    this.blog = {
      blogID:0,
      image: '',
      author: '',
      category: '',
      description:'',
      blogContent: ''
    };

    this.blogGroup = new FormGroup({
      image : new FormControl(null),
      author : new FormControl(),
      category : new FormControl(),
      description : new FormControl(this.description),
      blogContent : new FormControl('')
    });

    this.GetBlogById()
  }

  

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.image = reader.result as string;
      this.blogGroup.controls['image'].setValue(this.image);
    };
    reader.readAsDataURL(file);
  }

  GetBlogById(){
    try {
      let blog = {
        BlogID: this.BlogID
      };

      this.blogService.GetBlogByID(blog).subscribe(
        (data) => {
          this.blogGroup.setValue({
            image: data.image,
            author: data.author,
            category: data.category,
            description: data.description,
            blogContent:data.blogContent
          });
          console.log("Update Data: ",data)
        },
        (err: any) => {
          console.log(err.message);
        });
    } catch (ex) {
      this.logService.InsertExceptionLog(ex,"View Blog","loginBlog");
      console.log(ex);
    }
  }

  submit() {

    this.image = this.blogGroup.controls['image'].value;
    this.author = this.blogGroup.controls['author'].value;
    this.category = this.blogGroup.controls['category'].value;
    this.blogContent = this.blogGroup.controls['blogContent'].value;
    this.description = this.blogGroup.controls['description'].value;

        let blogData: Blog={
          blogID:this.BlogID,
          image:this.image !== 'undefined' || this.image !== undefined ? this.image : null,
          author: this.author,
          category: this.category,
          description: this.description,
          blogContent: this.blogContent
        }

        if(this.BlogID !== 0)
          {
            this.blogService.UpdateBlog(blogData).subscribe(
              (data) => {
                console.log('blog Updated successfully:',data);
                this.router.navigate(
                  ['view-blog']);
              },
              (err: any) => {
                console.log(err.message);
              });
          }
          else{
            this.blogService.InsertBlog(blogData).subscribe(
              (data) => {
                console.log('blog added successfully:',data);
              },
              (error: any) => {
                console.log(error);
              }
            );
          }
  }

  updateBlog(BlogData) {
    try {
     
    } catch (ex) {
      this.logService.InsertExceptionLog(ex,"View Blog","loginBlog");
      console.log(ex);
    }
  }

  close(){
    this.router.navigate(
      ['view-blog']);
  }
}
