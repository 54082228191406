import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LogService } from 'src/app/services/common/log.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordGroup : FormGroup;
  InvalidUsername = 0;
  IsEmailSent = 0;

  constructor(private router:Router,
    private companyService:CompanyService,
    private logService : LogService){}

  ngOnInit(): void {
    try{
      this.forgotPasswordGroup = new FormGroup({
        CompanyID : new FormControl(sessionStorage.getItem("CompanyID")),
        Username : new FormControl()
      });
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Forgot Password","ngOnInit");
      console.log("Error in ngOnInit : " + ex);
    }    
  }

  ResetPasswordForCompany(){
    try{
      this.IsEmailSent = 0;
      this.InvalidUsername = 0;
      if(this.forgotPasswordGroup.valid){
        this.companyService.ResetPasswordForCompany(this.forgotPasswordGroup.value).subscribe(
          (result) => {
            if(result.status > 0 && result.isSuccess == 1){
              this.IsEmailSent = 1;
              this.ngOnInit();
            }
            else{
              this.InvalidUsername = 1;
            }
          }, (err: Error) => {
            console.log(err.message);
          })
      }
    }catch(ex){
      this.logService.InsertExceptionLog(ex,"Forgot Password","ResetPasswordForCompany");
      console.log("Error in ResetPasswordForCompany : " + ex);
    }
  }

  ResetEmailSent(){
    this.IsEmailSent = 0;
  }

  RedirectToLogin(){
    this.router.navigate(['login']);
  }
}
