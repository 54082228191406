import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  constructor(private router: Router, private http: HttpClient) { }
  
  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  InsertSubscriber(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Subscriber/InsertSubscriber`, body, options);
  }

  AuthenticateSubscriber(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Subscriber/AuthenticateSubscriber`, body, options);
  }

  SetOtpAndEmailForSubscriber(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Subscriber/SetOtpAndEmailForSubscriber`, body, options);
  }

  ValidateOTPForSubscriber(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Subscriber/ValidateOTPForSubscriber`, body, options);
  }

  UpdateSubscriberPassword(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Subscriber/UpdateSubscriberPassword`, body, options);
  }

  GetPasswordFromInstituteEmail(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Subscriber/GetPasswordFromInstituteEmail`, body, options);
  }

  GetSubscriberDataForSubscriberID(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Subscriber/GetSubscriberDataForSubscriberID`, body, options);
  }

  UpdateSubscriberDetails(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Subscriber/UpdateSubscriberDetails`, body, options);
  }
}
