import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor() { }

  setdateformat: string = "dd/MM/yyyy";
  datesep: string = "/";
  initval: string = '01/04/2020';
  date = new Date();

  SetInitDate() {
    return this.initval;
  }

  ConvertToDate(dateStr) {
    var getdate = "";

    try {
      if (this.setdateformat == "dd" + this.datesep + "MM" + this.datesep + "yyyy") {
        if (dateStr.slice(-1) == "." || dateStr.slice(-1) == "/" || dateStr.slice(-1) == "-")
          dateStr = dateStr.Remove(dateStr.length - 1, 1);
        if (dateStr.includes(".") | dateStr.includes("/") | dateStr.includes("-")) {
          var setdays = null;
          var setmonth = null;
          var setyrs = null;
          var sep = 0;
          var dsep = 0;

          for (var i = 0; i <= dateStr.length - 1; i++) {
            if (dateStr[i] == "." || dateStr[i] == "/" || dateStr[i] == "-") {
              sep = sep + 1;

              if (sep < 3) {
                if (sep == 1 && setdays == null) {
                  setdays = parseInt(dateStr.substr(0, i));

                  if (setdays.toString().length == 1) {
                    setdays = "0" + setdays;
                  }

                  if (dateStr.substring(i + 1).includes(".") || dateStr.substring(i + 1).includes("/") || dateStr.substring(i + 1).includes("-")) {
                    dsep = i;

                    continue;
                  }
                  else {
                    setmonth = parseInt(dateStr.substring(i + 1));

                    if (setmonth.toString().length == 1) {
                      setmonth = "0" + setmonth;
                    }

                    if (parseInt(setmonth) <= 12) {
                      if (this.isleapYear(this.initval.substr(this.initval.length - 4, 4))) {

                        if (parseInt(setmonth) == 2) {
                          if (parseInt(setdays) < 30) {
                            getdate = setdays + this.datesep + setmonth + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                          }
                          else {
                            getdate = this.initval;
                          }
                        }
                        else if ((parseInt(setmonth) == 1) || (parseInt(setmonth) == 3) || (parseInt(setmonth) == 5) || (parseInt(setmonth) == 7) || (parseInt(setmonth) == 8) || (parseInt(setmonth) == 10) || (parseInt(setmonth) == 12)) {
                          if (parseInt(setdays) <= 31) {
                            getdate = setdays + this.datesep + setmonth + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                          }
                          else {
                            getdate = this.initval;
                          }
                        }
                        else if (parseInt(setdays) <= 30) {
                          getdate = setdays + this.datesep + setmonth + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                        }
                        else {
                          getdate = this.initval;
                        }
                      }
                      else if (parseInt(setmonth) == 2) {

                        if (parseInt(setdays) < 29) {
                          getdate = setdays + this.datesep + setmonth + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                        }
                        else {
                          getdate = this.initval;
                        }
                      }
                      else if ((parseInt(setmonth) == 1) || (parseInt(setmonth) == 3) || (parseInt(setmonth) == 5) || (parseInt(setmonth) == 7) || (parseInt(setmonth) == 8) || (parseInt(setmonth) == 10) || (parseInt(setmonth) == 12)) {
                        if (parseInt(setdays) <= 31)
                          getdate = setdays + this.datesep + setmonth + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                        else
                          getdate = this.initval;
                      }
                      else if (parseInt(setdays) <= 30)
                        getdate = setdays + this.datesep + setmonth + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                      else
                        getdate = this.initval;

                      break;
                    }
                    else {
                      getdate = this.initval;

                      break;
                    }
                  }
                }

                if (sep == 2) {
                  if (dateStr.substring(i + 1).includes(".") || dateStr.substring(i + 1).includes("/") || dateStr.substring(i + 1).includes("-")) {
                    getdate = this.initval;

                    break;
                  }
                  else {
                    setmonth = parseInt(dateStr.substr(dsep + 1, (i - (dsep + 1))));

                    if (setmonth.toString().length == 1) {
                      setmonth = "0" + setmonth;
                    }

                    setyrs = parseInt(dateStr.substring(i + 1));

                    if (setyrs.toString().length == 1) {
                      setyrs = "0" + setyrs;
                    }

                    if (setyrs.toString().length == 2) {
                      var curryr = this.date.getFullYear().toString().substr(0, 2);

                      setyrs = curryr + setyrs;
                    }

                    if (parseInt(setmonth) <= 12) {
                      if (this.isleapYear(setyrs)) {
                        if (parseInt(setmonth) == 2) {
                          if (parseInt(setdays) >= 30) {
                            getdate = this.initval;

                            break;
                          }
                        }
                        else if ((parseInt(setmonth) == 1) || (parseInt(setmonth) == 3) || (parseInt(setmonth) == 5) || (parseInt(setmonth) == 7) || (parseInt(setmonth) == 8) || (parseInt(setmonth) == 10) || (parseInt(setmonth) == 12)) {
                          if (parseInt(setdays) > 31) {
                            getdate = this.initval;

                            break;
                          }
                        }
                        else if (parseInt(setdays) > 30) {
                          getdate = this.initval;

                          break;
                        }
                      }
                      else if (parseInt(setmonth) == 2) {
                        if (parseInt(setdays) >= 29) {
                          getdate = this.initval;

                          break;
                        }
                      }
                      else if ((parseInt(setmonth) == 1) || (parseInt(setmonth) == 3) || (parseInt(setmonth) == 5) || (parseInt(setmonth) == 7) || (parseInt(setmonth) == 8) || (parseInt(setmonth) == 10) || (parseInt(setmonth) == 12)) {
                        if (parseInt(setdays) > 31) {
                          getdate = this.initval;

                          break;
                        }
                      }
                      else if (parseInt(setdays) > 30) {
                        getdate = this.initval;

                        break;
                      }
                    }
                    else {
                      getdate = this.initval;

                      break;
                    }

                    getdate = setdays + this.datesep + setmonth + this.datesep + setyrs;

                    break;
                  }
                }
              }
              else {
                getdate = this.initval;

                break;
              }
            }
          }
        }
        else {
          if (dateStr.toString().length == 1) {
            dateStr = "0" + dateStr;
          }

          if (this.isleapYear(this.initval.substr(this.initval.length - 4, 4))) {
            if (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 2) {
              if (parseInt(dateStr) < 30)
                getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
              else
                getdate = this.initval;
            }
            else if ((parseInt(this.initval.substr(this.initval.length - 7, 2)) == 1) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 3) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 5) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 7) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 8) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 10) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 12)) {
              if (parseInt(dateStr) <= 31)
                getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
              else
                getdate = this.initval;
            }
            else if (parseInt(dateStr) <= 30)
              getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
            else
              getdate = this.initval;
          }
          else if (this.initval.substr(this.initval.length - 7, 2) == "2") {
            if (parseInt(dateStr) < 29)
              getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
            else
              getdate = this.initval;
          }
          else if ((parseInt(this.initval.substr(this.initval.length - 7, 2)) == 1) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 3) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 5) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 7) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 8) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 10) || (parseInt(this.initval.substr(this.initval.length - 7, 2)) == 12)) {
            if (parseInt(dateStr) <= 31)
              getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
            else
              getdate = this.initval;
          }
          else if (parseInt(dateStr) <= 30)
            getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
          else
            getdate = this.initval;
        }
      }
      else {

        if (dateStr.slice(-1) == "." || dateStr.slice(-1) == "/" || dateStr.slice(-1) == "-")
          dateStr = dateStr.Remove(dateStr.length - 1, 1);

        if (dateStr.includes(".") || dateStr.includes("/") || dateStr.includes("-")) {

          var setdays;
          var setmonth;
          var setyrs;

          var sep = 0;

          var dsep = 0;

          for (var i = 0; i <= dateStr.length - 1; i++) {
            if (dateStr[i] == "." || dateStr[i] == "/" || dateStr[i] == "-") {
              sep = sep + 1;

              if (sep < 3) {
                if (sep == 1 && setmonth == null) {
                  setmonth = parseInt(dateStr.substr(0, i));

                  if (setmonth.toString().length == 1) {
                    setmonth = "0" + setmonth;
                  }

                  if (dateStr.substring(i + 1).includes(".") || dateStr.substring(i + 1).includes("/") || dateStr.substring(i + 1).includes("-")) {
                    dsep = i;

                    continue;
                  }
                  else {
                    setdays = parseInt(dateStr.substring(i + 1));

                    if (setdays.toString().length == 1) {
                      setdays = "0" + setdays;
                    }

                    if (parseInt(setmonth) <= 12) {
                      if (this.isleapYear(this.initval.substr(this.initval.length - 4, 4))) {
                        if (parseInt(setmonth) == 2) {
                          if (parseInt(setdays) < 30)
                            getdate = setmonth + this.datesep + setdays + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                          else
                            getdate = this.initval;
                        }
                        else if ((parseInt(setmonth) == 1) || (parseInt(setmonth) == 3) || (parseInt(setmonth) == 5) || (parseInt(setmonth) == 7) || (parseInt(setmonth) == 8) || (parseInt(setmonth) == 10) || (parseInt(setmonth) == 12)) {
                          if (parseInt(setdays) <= 31)
                            getdate = setmonth + this.datesep + setdays + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                          else
                            getdate = this.initval;
                        }
                        else if (parseInt(setdays) <= 30)
                          getdate = setmonth + this.datesep + setdays + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                        else
                          getdate = this.initval;
                      }
                      else if (parseInt(setmonth) == 2) {
                        if (parseInt(setdays) < 29)
                          getdate = setmonth + this.datesep + setdays + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                        else
                          getdate = this.initval;
                      }
                      else if ((parseInt(setmonth) == 1) || (parseInt(setmonth) == 3) || (parseInt(setmonth) == 5) || (parseInt(setmonth) == 7) || (parseInt(setmonth) == 8) || (parseInt(setmonth) == 10) || (parseInt(setmonth) == 12)) {
                        if (parseInt(setdays) <= 31)
                          getdate = setmonth + this.datesep + setdays + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                        else
                          getdate = this.initval;
                      }
                      else if (parseInt(setdays) <= 30)
                        getdate = setmonth + this.datesep + setdays + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                      else
                        getdate = this.initval;

                      break;
                    }
                    else {
                      getdate = this.initval;

                      break;
                    }
                  }
                }

                if (sep == 2) {
                  if (dateStr.substring(i + 1).includes(".") || dateStr.substring(i + 1).includes("/") || dateStr.substring(i + 1).includes("-")) {
                    getdate = this.initval;

                    break;
                  }
                  else {
                    setdays = parseInt(dateStr.substr(dsep + 1, (i - (dsep + 1))));

                    if (setdays.toString().length == 1) {
                      setdays = "0" + setdays;
                    }

                    setyrs = parseInt(dateStr.substring(i + 1));

                    if (setyrs.toString().length == 1) {
                      setyrs = "0" + setyrs;
                    }

                    if (setyrs.toString().length == 2) {
                      var curryr = this.date.getFullYear().toString().substr(0, 2);
                      setyrs = curryr + setyrs;
                    }

                    if (parseInt(setmonth) <= 12) {
                      if (this.isleapYear(setyrs)) {
                        if (parseInt(setmonth) == 2) {
                          if (parseInt(setdays) >= 30) {
                            getdate = this.initval;

                            break;
                          }
                        }
                        else if ((parseInt(setmonth) == 1) || (parseInt(setmonth) == 3) || (parseInt(setmonth) == 5) || (parseInt(setmonth) == 7) || (parseInt(setmonth) == 8) || (parseInt(setmonth) == 10) || (parseInt(setmonth) == 12)) {
                          if (parseInt(setdays) > 31) {
                            getdate = this.initval;

                            break;
                          }
                        }
                        else if (parseInt(setdays) > 30) {
                          getdate = this.initval;

                          break;
                        }
                      }
                      else if (parseInt(setmonth) == 2) {
                        if (parseInt(setdays) >= 29) {
                          getdate = this.initval;

                          break;
                        }
                      }
                      else if ((parseInt(setmonth) == 1) || (parseInt(setmonth) == 3) || (parseInt(setmonth) == 5) || (parseInt(setmonth) == 7) || (parseInt(setmonth) == 8) || (parseInt(setmonth) == 10) || (parseInt(setmonth) == 12)) {
                        if (parseInt(setdays) > 31) {
                          getdate = this.initval;

                          break;
                        }
                      }
                      else if (parseInt(setdays) > 30) {
                        getdate = this.initval;

                        break;
                      }
                    }
                    else {
                      getdate = this.initval;

                      break;
                    }

                    getdate = setmonth + this.datesep + setdays + this.datesep + setyrs;

                    break;
                  }
                }
              }
              else {
                getdate = this.initval;

                break;
              }
            }
          }
        }
        else {
          if (dateStr.toString().length == 1) {
            dateStr = "0" + dateStr;
          }

          var flag = 0;

          if (parseInt(dateStr) <= 12) {
            if (this.isleapYear(this.initval.substr(this.initval.length - 4, 4))) {
              if (parseInt(dateStr) == 2) {
                if (parseInt(setdays) >= 30) {
                  getdate = this.initval;
                  flag = 1;
                }
              }
              else if ((parseInt(dateStr) == 1) || (parseInt(dateStr) == 3) || (parseInt(dateStr) == 5) || (parseInt(dateStr) == 7) || (parseInt(dateStr) == 8) || (parseInt(dateStr) == 10) || (parseInt(dateStr) == 12)) {
                if (parseInt(setdays) > 31) {
                  getdate = this.initval;
                  flag = 1;
                }
              }
              else if (parseInt(setdays) > 30) {
                getdate = this.initval;

                flag = 1;
              }
            }
            else if (parseInt(dateStr) == 2) {
              if (parseInt(setdays) >= 29) {
                getdate = this.initval;

                flag = 1;
              }
            }
            else if ((parseInt(dateStr) == 1) || (parseInt(dateStr) == 3) || (parseInt(dateStr) == 5) || (parseInt(dateStr) == 7) || (parseInt(dateStr) == 8) || (parseInt(dateStr) == 10) || (parseInt(dateStr) == 12)) {
              if (parseInt(setdays) > 31) {
                getdate = this.initval;

                flag = 1;
              }
            }
            else if (parseInt(setdays) > 30) {
              getdate = this.initval;

              flag = 1;
            }
          }
          else {
            getdate = this.initval;

            flag = 1;
          }

          if (flag == 0) {
            if (this.isleapYear(this.initval.substr(this.initval.length - 4, 4))) {
              if (parseInt(dateStr) == 2) {
                if (parseInt(this.initval.substr(this.initval.length - 7, 2)) < 30)
                  getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                else
                  getdate = this.initval;
              }
              else if ((parseInt(dateStr) == 1) || (parseInt(dateStr) == 3) || (parseInt(dateStr) == 5) || (parseInt(dateStr) == 7) || (parseInt(dateStr) == 8) || (parseInt(dateStr) == 10) || (parseInt(dateStr) == 12)) {
                if (parseInt(this.initval.substr(this.initval.length - 7, 2)) <= 31)
                  getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
                else
                  getdate = this.initval;
              }
              else if (parseInt(this.initval.substr(this.initval.length - 7, 2)) <= 30)
                getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
              else
                getdate = this.initval;
            }
            else if (parseInt(dateStr) == 2) {
              if (parseInt(this.initval.substr(this.initval.length - 7, 2)) < 29)
                getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
              else
                getdate = this.initval;
            }
            else if ((parseInt(dateStr) == 1) || (parseInt(dateStr) == 3) || (parseInt(dateStr) == 5) || (parseInt(dateStr) == 7) || (parseInt(dateStr) == 8) || (parseInt(dateStr) == 10) || (parseInt(dateStr) == 12)) {
              if (parseInt(this.initval.substr(this.initval.length - 7, 2)) <= 31)
                getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
              else
                getdate = this.initval;
            }
            else if (parseInt(this.initval.substr(this.initval.length - 7, 2)) <= 30)
              getdate = dateStr + this.datesep + this.initval.substr(this.initval.length - 7, 2) + this.datesep + this.initval.substr(this.initval.length - 4, 4);
            else
              getdate = this.initval;
          }
        }
      }
    }

    catch (err) {
      getdate = this.initval;
    }

    return getdate;
  }

  isleapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }
}
