<app-web-navbar></app-web-navbar>

<div class="main-div row col-12 col-lg-10 mt-5 mb-4 ms-auto me-auto justify-content-center align-items-center">

        <div class="forgot-password-illustration-container col-md-6 d-none d-md-flex order-md-2">
            <img src="../../../assets/images/forgot-password/Forgot password-cuate.svg" alt="">
        </div>
        <div class="forgot-password-form col-10 col-md-6 order-md-1">
        <div class="form-heading row col-12 ms-2 mt-3 mb-3">
            <h3 class="col-12 fw-bold">Forgot Password</h3>
        </div>
        <mat-stepper orientation="vertical" [linear]="true" #stepper>
            <mat-step [stepControl]="firstFormGroup" [editable]="false">
            <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Enter Username</ng-template>
                <mat-form-field appearance="outline">
                    <!-- <mat-label>EmailID</mat-label> -->
                    <input matInput placeholder="pat@example.com" formControlName="EmailID" required>
                    <mat-error>Please enter email-id.</mat-error>
                </mat-form-field>
                <span *ngIf="IsEmailValid" style="color: red;">Invalid Email-ID. Please try again.</span>
                <div>
                    <button mat-button (click)="ResetPassword(stepper)">Next</button>
                    <button mat-button (click)="RedirectToLogin()">Cancel</button>
                </div>
            </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" [editable]="false" label="Validate OTP">
            <form [formGroup]="secondFormGroup">
                <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="IsSuccessOTP">
                    <strong>OTP is sent to your registred email-id. Please check.</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" 
                        (click)="ResetIsSuccessOTP()"></button>  
                </div>
                <mat-form-field appearance="outline">
                    <!-- <mat-label>OTP</mat-label> -->
                    <input matInput formControlName="OTP" required>
                    <mat-error>Please enter OTP.</mat-error>
                </mat-form-field>
                <span *ngIf="IsOTPValid" style="color: red;">Invalid OTP. Please try again.</span>
                <div>
                    <button mat-button (click)="ValidateOTP(stepper)">Next</button>
                    <button mat-button (click)="RedirectToLogin()">Cancel</button>
                </div>
            </form>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup" [editable]="false" label="Reset Your Password">
                <form [formGroup]="thirdFormGroup">
                    <mat-form-field appearance="outline">
                        <!-- <mat-label>Enter new password</mat-label> -->
                        <input matInput formControlName="Password" [type]="hide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error>Please enter password.</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <!-- <mat-label>Confirm new password</mat-label> -->
                        <input matInput formControlName="ConfirmPassword" [type]="confirmhide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="confirmhide = !confirmhide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirmhide">
                        <mat-icon>{{confirmhide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error>Please enter password.</mat-error>
                    </mat-form-field>
                    <span *ngIf="IsPasswordNotMatch" style="color: red;">Password not matched. Please try again.</span>
                    <div>
                        <button mat-button (click)="ResetSavePassword(stepper)">Reset Password</button>
                        <button mat-button (click)="RedirectToLogin()">Cancel</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Done</ng-template>
                <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="IsSuccessOTP">
                    <strong>Your password is reset successfully. <br/> Please login to your account.</strong>
                </div>            
                <button mat-button (click)="RedirectToLogin()">Login</button>   
              </mat-step>
        </mat-stepper>
    </div>
</div>

<app-web-footer></app-web-footer>