import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipping-and-delivery',
  templateUrl: './shipping-and-delivery.component.html',
  styleUrls: ['./shipping-and-delivery.component.css']
})
export class ShippingAndDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
