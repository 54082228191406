import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-web-navbar',
  templateUrl: './web-navbar.component.html',
  styleUrls: ['./web-navbar.component.css']
})
export class WebNavbarComponent implements OnInit {
  isNavbarOpen = false;

  constructor(private elRef: ElementRef,private router: Router) {} 

  ngOnInit(): void {

  document.addEventListener('click', this.closeNavbarOutside.bind(this));
  
  }
  toggleNavbar(): void {
    this.isNavbarOpen = !this.isNavbarOpen;

  }

  redirectTo(page){
    this.router.navigate([page]);
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent): void {
    this.isNavbarOpen = false;
  }

  
  closeNavbarOutside(event: Event): void {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.isNavbarOpen = false;
    }
  }
}
