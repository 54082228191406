<div class="main-div row col-12 col-lg-10 mt-5 mb-4 ms-auto me-auto justify-content-center align-items-center">
    <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="IsEmailSent">
        <strong>Password is sent to your registered email address. <br>
            please <a routerLink="/login">login</a></strong>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
            (click)="ResetEmailSent()"></button>  
    </div>

    <div class="forgot-password-illustration-container col-12 col-md-6 d-block d-md-flex  order-2">
        <img src="../../../assets/images/forgot-password/Forgot password-cuate.svg" alt="">
    </div>
      
        <form class="forgot-password-form col-10 col-md-6 order-1 pb-3" [formGroup]="forgotPasswordGroup">

            <div class="form-heading row col-12 ms-2 mt-3 mb-3">
                <h3 class="col-12 fw-bold">Forgot Password</h3>
            </div>
            <div class="col-12 row">
                <label>Username</label>
                <mat-form-field appearance="outline">
                    <input matInput class="col-12 ms-2 " placeholder="pat@example.com" formControlName="Username" required >
                    <mat-error>Please enter username</mat-error>
                </mat-form-field>
                <p style="text-align: center;margin-top: 4px;cursor: pointer;" *ngIf="InvalidUsername">
                    <span style="color: red;">Invalid Username.</span>
                </p>
                <div class="col-12 row mt-3">
                    <div class="col-6">
                        <button  class="primary-button col-12"  (click)="ResetPasswordForCompany()">Submit</button> 
                    </div>
                    <div class="col-6">
                        <button  class="secondary-button col-12"  (click)="RedirectToLogin()">Cancel</button>
                    </div>
                </div> 
            </div>
        </form>
</div>