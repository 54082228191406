import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { packageDetails } from 'src/app/components/interface/packageDetails';
import { PaymentService } from 'src/app/services/common/payment.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { environment } from 'src/environments/environment';

declare var Razorpay: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent {
  @ViewChild('stepper') stepper!: MatStepper;
  isSummary = false;
  isChecked = true;
  isPricingCompleted = false;
  isPaymentCompleted = false;
  packageDetails : packageDetails[] = [];
  allPackageDetails : any[] = [];
  subscriberID : string;
  selectedPackage : any;
  subscriberData : any;
  subscriberFormGroup : FormGroup;
  subscriberEmail : string;
  subscriptionPaymentFormGroup : FormGroup;
  subscriptionPaymentID : number = 0;

  constructor(private paymentService:PaymentService,private route:ActivatedRoute,
    private subscriberService : SubscriberService,private router:Router){}

  ngOnInit(){
    this.subscriberID = "";
    this.isPaymentCompleted = sessionStorage.getItem("isPaymentCom") == "1" ? true : false;
    this.route.queryParamMap.subscribe(params => {
      if (params.has('subscriberID')) {
        this.subscriberID = params.get('subscriberID');
      }
    });

    this.subscriberFormGroup = new FormGroup({
      firstName : new FormControl(""),
      lastName : new FormControl(""),
      billingAddress : new FormControl(""),
      city : new FormControl(""),
      state : new FormControl(""),
      pinCode : new FormControl(""),
      country : new FormControl("India"),
      phoneNumber : new FormControl("")
    });

    this.getPackageDetails();
    this.getSubscriberDataForSubscriberID();
  }

  nextStep(stepper: MatStepper,selectedPackage) {
    this.isPricingCompleted = true;
    setTimeout(() => {
      stepper.next();
    });
    this.selectedPackage = selectedPackage;
  }

  payNow() {
    var price = 0;

    for(var i=0;i<this.allPackageDetails.length;i++){
      if(this.selectedPackage.packageID == this.allPackageDetails[i].packageID){
        price = this.allPackageDetails[i].totalPrice;
        break;
      }
    }
    const RozarpayOptions = {
      key: environment.razorKey,
      amount: price * 100,
      currency: 'INR',
      description: 'Complete your payment for subscription',
      name: 'Asquare ERP Solutions',
      image: '../../../../../assets/images/logo.png',
      prefill: {
        name: this.subscriberFormGroup.value.firstName,
        email: this.subscriberEmail,
        phone: this.subscriberFormGroup.value.phoneNumber
      },
      handler: function(response) {
        if(response.razorpay_payment_id) {
          this.updatePaymentStatus(response.razorpay_payment_id);
        } else {
          this.updatePaymentStatus("",response.error.description);
        }
      }.bind(this),
      theme: {
        color: '#6466e3'
      },
      modal: {
        ondismiss:  () => {
          console.log('dismissed')
        }
      }
    }

    Razorpay.open(RozarpayOptions)
  }

  redirectToPayNow(){
    try{
      this.insertPaymentDataForSubscription();
    }
    catch(ex){
      console.log("Error in redirectToPayNow : " + ex);
    }
  }

  toggleAgree() {
    if(this.isChecked) {
      this.isChecked = false;
    } else {
      this.isChecked = true;
    }
  }

  onPaymentClick(stepper: MatStepper){
    setTimeout(() => {
      stepper.next();
    });
  }

  getPackageDetails(){
    try{
      this.paymentService.GetPackageDetails().subscribe(
      (result) => {
        this.packageDetails = result;
        this.allPackageDetails = result;

        this.packageDetails = this.packageDetails.slice(1);
      }, (err: Error) => {
        console.log(err.message);
      });
    }
    catch(ex){
      console.log("Error in Payment : " + ex);
    }
  }

  getSubscriberDataForSubscriberID(){
    try{
      var formGroup = new FormGroup({
        SubscriberID : new FormControl(this.subscriberID)
      });

      this.subscriberService.GetSubscriberDataForSubscriberID(formGroup.value)
      .subscribe(
        (result) => {
          this.subscriberData = result;
          this.subscriberEmail = result.emailID;

          var name = [];
          if(result.name != null || result.name != ""){
            name = result.name.split(" ");
          }

          // this.subscriberFormGroup = new FormGroup({
          //   firstName : new FormControl(name[0]),
          //   lastName : new FormControl(name[1]),
          //   billingAddress : new FormControl(result.address),
          //   city : new FormControl(result.city),
          //   state : new FormControl(result.state),
          //   pinCode : new FormControl(result.pincode),
          //   country : new FormControl("India"),
          //   phoneNumber : new FormControl(result.mobileNumber)
          // });         
        }, (err: Error) => {
          console.log(err.message);
        });
    }
    catch(ex){
      console.log("Error in getSubscriberDataForSubscriberID : " + ex);
    }
  }

  insertPaymentDataForSubscription(){
    try{
      this.subscriptionPaymentFormGroup = new FormGroup({
        SubscriberID : new FormControl(parseInt(this.subscriberID)),
        PackageID : new FormControl(this.selectedPackage.packageID),
        FirstName : new FormControl(this.subscriberFormGroup.value.firstName),
        LastName : new FormControl(this.subscriberFormGroup.value.lastName),
        BillingAddress : new FormControl(this.subscriberFormGroup.value.billingAddress),
        City : new FormControl(this.subscriberFormGroup.value.city),
        State : new FormControl(this.subscriberFormGroup.value.state),
        Pincode : new FormControl(this.subscriberFormGroup.value.pinCode),
        Country : new FormControl(this.subscriberFormGroup.value.country),
        PhoneNumber : new FormControl(this.subscriberFormGroup.value.phoneNumber),
        Amount : new FormControl(this.selectedPackage.totalPrice)
      });

      this.paymentService.InsertPaymentDataForSubscription(this.subscriptionPaymentFormGroup.value)
      .subscribe(
        (result) => {      
          if(result > 0){            
            this.subscriptionPaymentID = result;
            this.payNow();
          } 
        }, (err: Error) => {
          console.log(err.message);
        });

    }catch(ex){
      console.log("Error in insertPaymentDataForSubscription : " + ex);
    }
  }

  updatePaymentStatus(TransationID?,Error?){
    try{
      var updatePaymentFormGroup = new FormGroup({
        SubscriptionPaymentID : new FormControl(this.subscriptionPaymentID),
        TransationID : new FormControl(TransationID),
        Error : new FormControl(Error)
      });

      this.paymentService.UpdatePaymentStatus(updatePaymentFormGroup.value)
      .subscribe(
        (result) => {      
          console.log(result);
          if(result.value.isSuccess == 1){
            sessionStorage.setItem("pid",result.value.packageID);
            sessionStorage.setItem("isPaymentCom","1");
            this.router.navigate(
              ['view-company'],
              { queryParams: { subscriberID: this.subscriberID } }
            );
          }
        }, (err: Error) => {
          console.log(err.message);
        });
    }catch(ex){
      console.log("Error in UpdatePaymentStatus : "+ ex);
    }
  }

  redirectTo(page){
    this.router.navigate([page]);
  }
}
