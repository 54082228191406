import { AuthService } from './../../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isPlatform, Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private cookieService: CookieService) {}

  subscriberString = '';

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve) => {
      const blogUserString = sessionStorage.getItem('blogUser');
      const companyUserString = sessionStorage.getItem('companyUser');

      if (isPlatform('capacitor')) {
        const subscriber = await this.authService.getPreferenceSubscriberDetails();
        if (subscriber) {
          resolve(true);
        } else {
          this.router.navigate(['subscriber-login']);
          resolve(false);
        }
      } else {
        this.subscriberString = sessionStorage.getItem('subscriber');
        if (!this.subscriberString) {
          this.subscriberString = this.cookieService.get('subscriber');
        }
  
        if (blogUserString || companyUserString || this.subscriberString) {
          resolve(true);
        } else {
          this.router.navigate(['subscriber-login']);
          resolve(false);
        }
      }
    });
  }
}
