<app-web-navbar></app-web-navbar>

<div class="main-div row col-12 ms-2 me-2 mt-5">
    <div class="row col-12 col-sm-8 col-md-12 ms-auto me-auto">
        <div class="contact-form row col-12 col-md-5">
            <div class="contact-header row col-12 mb-4">
                <h3 class="fw-bold">Request a demo</h3>
                <p class="fw-bolder">We warmly welcome your valuable feedback and would greatly appreciate the opportunity to hear from you.
                </p>
            </div>
            <form action="" class="row col-12">
                <div class="inputs-div row col-12 mb-4">
                    <label for="name" class="col-12">Name</label>
                    <input type="text" name="name" id="" placeholder="Name" class="pt-1 pb-1">
                </div>
                <div class="inputs-div row col-12 mb-4">
                    <label for="email" class="col-12">Email</label>
                    <input type="email" name="email" id="" placeholder="Email" class="pt-1 pb-1">
                </div>
                <div class="inputs-div row col-12 mb-4">
                    <label for="phoneNumber" class="col-12">Phone Number</label>
                    <input type="tel" name="phoneNumber" id="" placeholder="Phone Number" class="pt-1 pb-1">
                </div>
                <div class="inputs-div row col-12 mb-4">
                    <label for="message" class="col-12">Message</label>
                    <textarea name="message" placeholder="Message">
                    </textarea>
                </div>
                <div class="inputs-div row col-12 mb-4">
                    <a routerLink="" class="message-button col-12 col-md-6 pt-2 pb-2 text-center">Send message</a>
                </div>
            </form>
        </div>
        <div class="map-div row col-12 col-md-7 mb-5">
            <div class="map-header row col-12 mb-4 align-content-start">
                <p class="mb-0 fw-bolder mb-4">About the company</p>
                <h4 class="mb-0 fw-bold">Made with care right here in Nashik</h4>
                <p class="mb-0" style="
                color: var(--blacks--black-300);
            ">Merchant Legal Entity Name <span class="fw-semibold"> A-SQUARE ERP SOLUTIONS PRIVATE LIMITED'S </span> </p>
            </div>
            <div class="map-container col-12 mb-4 h-75">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7498.173046368691!2d73.768762!3d20.004883!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb9f2b78c081%3A0x33607560c977d39!2sA-Square%20ERP%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1687035760020!5m2!1sen!2sin"
                    style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>

    <div class="contact-info row col-12 col-sm-8 col-md-12 ms-auto me-auto mt-md-4 pt-5">
        <div class="contact-info-card row col-8 col-sm-8 col-md-4 mb-4 me-1">
            <i class="fas fa-envelope mb-2 ms-3 align-self-center"></i>
            <h5 class="col-12 mb-2">Chat with sales</h5>
            <p class="col-12 mb-2">Speak to out friendly team</p>
            <p class="fw-bold col-12 mb-2 text-top word-wrap word-break">adityak@asquareerpsolutions.com</p>
        </div>

        <div class="contact-info-card row col-8 col-sm-8 col-md-4 mb-4">
            <i class="fas fa-location-arrow mb-2 ms-3"></i>
            <h5 class="col-12 mb-2">Visit us</h5>
            <p class="col-12 mb-2">Visit our office</p>
            <p class="fw-bold col-12 mb-2">Morya Apartment, College Road, Nashik MAHARASTRA 422005</p>
            <p class="fw-bold col-12 mb-2">22, Sahajeevan Colony, College Road, Nashik MAHARASTRA 422005</p>
            
        </div>

        <div class="contact-info-card row col-8 col-sm-8 col-md-4 mb-4">
            <i class="fas fa-phone mb-2 ms-3"></i>
            <h5 class="col-12 mb-2">Call us</h5>
            <p class="col-12 mb-2">Mon-Fri from 8am to 5pm</p>
            <p class="fw-bold col-12 mb-2">+91 9422283398 <br>
                +91 8766455332 </p>
        </div>
    </div>

    <div class="custom-cta row col-12 pb-4 col-sm-8 col-md-12 mt-4 ms-auto me-auto justify-content-md-center text-md-center">
        <h4 class="mb-2">Start your free trial </h4>
        <p class="mb-2">Join over 500+ businesses already growing with A-square Erp Solutions</p>
        <a (click)="redirectTo('subscriber-login')" class="get-started-button mb-2 col-5 col-md-3 text-center pt-1 pb-1 me-2 me-md-3">Get Started</a> 
        <a routerLink="" class="learn-more-button col-5 col-md-3 text-center pt-1 pb-1 mb-2">Learn more</a>
    </div>
</div>

<app-web-footer></app-web-footer>