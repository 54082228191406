import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material.module';
import { MatStepperModule } from '@angular/material/stepper';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonInterceptor } from './common/http-inteptor/common.interceptor';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ReadonlyDirective } from './app-readonly.directive';
import { SubscriberSignupComponent } from './components/subscriber-signup/subscriber-signup.component';
import { SubscriberLoginComponent } from './components/subscriber-login/subscriber-login.component';
import { ViewCompanyComponent } from './components/view-company/view-company.component';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SubscriberForgotPasswordComponent } from './components/subscriber-forgot-password/subscriber-forgot-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LandingPageComponent } from './components/front-end/web-pages/landing-page/landing-page.component';
import { AboutUsComponent } from './components/front-end/web-pages/about-us/about-us.component';
import { ContactUsComponent } from './components/front-end/web-pages/contact-us/contact-us.component';
import { PricingComponent } from './components/front-end/web-pages/pricing/pricing.component';
import { PaymentComponent } from './components/front-end/web-pages/payment/payment.component';
import { TermsAndConditionsComponent } from './components/front-end/web-pages/termsAndConditions/termsAndConditions.component';
import { PrivacyPolicyComponent } from './components/front-end/web-pages/privacyPolicy/privacyPolicy.component';
import { ShippingAndDeliveryComponent } from './components/front-end/web-pages/shipping-and-delivery/shipping-and-delivery.component';
import { CancellationAndRefundComponent } from './components/front-end/web-pages/cancellation-and-refund/cancellation-and-refund.component';
import { WebFooterComponent } from './components/front-end/web-components/web-footer/web-footer.component';
import { WebNavbarComponent } from './components/front-end/web-components/web-navbar/web-navbar.component';
import { FormsModule } from '@angular/forms';
import { AmountInWordsPipe } from './pipes/amount-in-words.pipe';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}
import { DynamicDimensionsDirective } from 'src/app/common/directives/dynamic-dimensions.directive';
import { BlogLoginComponent } from './components/blog/blog-login/blog-login.component';
import { AddBlogComponent } from './components/blog/add-blog/add-blog.component';
import { ViewBlogComponent } from './components/blog/view-blog/view-blog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    SubscriberSignupComponent,
    SubscriberLoginComponent,
    ViewCompanyComponent,
    AddCompanyComponent,
    SubscriberForgotPasswordComponent,
    ForgotPasswordComponent,
    ReadonlyDirective,
    LandingPageComponent,
    AboutUsComponent,
    ContactUsComponent,
    PricingComponent,
    PaymentComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ShippingAndDeliveryComponent,
    CancellationAndRefundComponent,
    WebFooterComponent,
    WebNavbarComponent,
    AmountInWordsPipe,
    DynamicDimensionsDirective,
    BlogLoginComponent,
    AddBlogComponent,
    ViewBlogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgSelectModule,
    NgxCaptchaModule,
    FormsModule,
    MatStepperModule,
    AngularEditorModule,
  ],
  exports:[
    AddCompanyComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe,
    AmountInWordsPipe,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule  { }
