import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Blog } from '../components/interface/Blog';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private router: Router, private http: HttpClient) { }

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  GetAllBlog(user): Observable<any> {
    const body = new HttpParams({fromObject: user});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Blog/GetAllBlog`, body, options);
  }

  GetBlogByID(Blog): Observable<any> {
    const body = new HttpParams({fromObject:Blog});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Blog/GetBlogByID`, body, options);
  }

  InsertBlog(blogData): Observable<any> {
    const body = new HttpParams({fromObject:blogData});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Blog/InsertBlog`, body, options);
  }

  UpdateBlog(blogData): Observable<any> {
    const body = new HttpParams({fromObject:blogData});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Blog/UpdateBlog`, body, options);
  }

  DeleteBlog(BlogID): Observable<any> {
    const body = new HttpParams({fromObject:BlogID});
    const options = { headers: this.headers };
    return this.http.post(`${environment.ASquareAPIURL}Blog/DeleteBlog`, body, options);
  }
}
