<app-web-navbar></app-web-navbar>

<div class="main-div row col-12 col-lg-8 mt-4 mb-4 ms-auto me-auto justify-content-center">
    <div class="subscriber-signup-header row col-12 col-lg-6 ms-0 d-none d-lg-flex order-2">
        <div class="stairs-top-left">
            <img src="../../assets/images/subscriber-login/stair-top-left.png" alt="">
        </div>

        <div class="subscriber-signup-content row col-12 mt-4 ">
            <div class="subscriber-signup-img mt-lg-4 col-lg-9 mb-5 mb-lg-0 col-lg-7 ms-auto me-auto">
                <img src="../../assets/images/subscriber-login/subscriber-login-img.png" alt="">
            </div>
            <div class="subscriber-signup-description row col-12 col-lg-8 ms-auto me-auto mb-4 align-content-lg-start">
                <h3 class="col-12 text-center">Take control of your business today!</h3>
                <p class="col-12 text-center"> Track new sales, invoices, and important transactions. Take control of
                    your business today!</p>
            </div>
        </div>

        <div class="stairs-bottom-right">
            <img src="../../assets/images/subscriber-login/stair-bottom-right.png" alt="">
        </div>
    </div>
    <div class="subscriber-signup row col-12 col-sm-8 col-md-6 col-lg-6 mt-4 mt-lg-0 me-lg-1 ps-2 pe-0 justify-content-center order-1">
        <form class="subscriber-form" [formGroup]="subscriberformGroup"
            (ngSubmit)="SubscriberSignUp()">

            <span *ngIf="isShowErrorMessage" style="color: var(--reds--red-300);">**Please Fill All Fields</span>

            <div class="company-logo col-4">
                <img src="../../assets/images/logo/logo.png" alt="" class="col-12">
            </div>
            <div class="form-heading row col-12 ms-2 mt-3 mb-3">
                <h3 class="col-12 fw-bold ps-0">Sign up</h3>
                <p class="col-12 fw-bolder ps-0">See your growth and get sales reports</p>
            </div>
            <!-- <div class="row col-12 ms-2">
                <div class="google-signup mb-3 pt-2 pb-2">
                    <img src="../../../assets/images/subscriber-signup/google.png" alt="">
                    <p class=" mb-0 text-left">Sign up with Google</p>
                </div>
            </div> -->

            <div class="row col-12 ms-2" >
                <!-- <div class="col-sm-2"></div> -->
                <div class="col-sm-12 ps-0 pt-1 btn btn-outline-google" id="google-signup" *ngIf="!platform.is('capacitor')"></div>
                <div class="google-login mb-3 pt-2 pb-2" id="google-button" (click)="signUpWithGoogleAndroid()"
                    *ngIf="platform.is('capacitor')">
                    <img src="../../../assets/images/subscriber-signup/android_neutral_rd_SU.svg" alt="">
                </div>
                <!-- <div class="col-sm-2"></div> -->
            </div>

            <div class="row col-12 ms-2">
                <div class="sign-up-or row col-12 mb-3 ps-0 align-items-center justify-content-center">
                    <hr>
                    <div>
                        <p> or Sign up with Email</p>
                    </div>
                    <hr>
                </div>
            </div>

            <div class="row col-12 ms-2">
                <div class="inputs-div row col-12">
                    <label for="name" class="col-12 col-lg-5 ms-0 mb-2 mb-lg-0 ps-0">Name*</label>
                    <input type="text" formControlName="Name" class="col-12 col-lg-7 mb-3 pt-1 pb-1" placeholder="Name" required>
                </div>
            </div>

            <div class="row col-12 ms-2 justify-content-between">
                <div class="inputs-div row col-12">
                    <label for="mobileNumber" class="col-12 col-lg-5  ms-0 mb-2 mb-lg-0 ps-0">Mobile Number*</label>
                    <input type="tel" formControlName="MobileNumber" class="col-9 col-lg-5 mb-3 pt-1 pb-1" #mobileNumber maxlength="10" placeholder="Ex. 9999999999" required>
                    <span class="col-3 col-lg-2 pe-0 text-end">{{mobileNumber.value.length}} / 10</span>
                </div>

                <div class="inputs-div row col-12">
                    <label for="email" class="col-12 col-lg-5   ms-0 mb-2 mb-lg-0 ps-0">Email*</label>
                    <input type="email" formControlName="EmailID" class="col-12 col-lg-7 mb-3 pt-1 pb-1" placeholder="pat@example.com" required>
                </div>
            </div>

            <div class="row col-12 ms-2">
                <div class="inputs-div row col-12">
                    <label for="address" class="col-12 col-lg-5 ms-0 mb-2 mb-lg-0 ps-0">Address*</label>
                    <textarea type="text" formControlName="Address" class="col-12 col-lg-7 mb-3 pt-1 pb-1" placeholder="Ex. Address" required></textarea>
                </div>
            </div>

            <div class="row col-12 ms-2 justify-content-between">
                <div class="inputs-div row col-12">
                    <label for="state" class="col-12 col-lg-5 ms-0 mb-2 mb-lg-0 ps-0">State*</label>
                    <input type="text" formControlName="State" class="col-12 col-lg-7 mb-3 pt-1 pb-1" placeholder="State" required>
                </div>
                <div class="inputs-div row col-12">
                    <label for="city" class="col-12 col-lg-5 ms-0 mb-2 mb-lg-0 ps-0">City*</label>
                    <input type="text" formControlName="City" class="col-12 col-lg-7 mb-3 pt-1 pb-1" placeholder="City" required>
                </div>
            </div>

            <div class="row col-12 ms-2 justify-content-between">
                <div class="inputs-div row col-12">
                    <label for="pincode" class="col-12 col-lg-5 ms-0 mb-2 mb-lg-0 ps-0">Pin code*</label>
                    <input type="text" maxlength="6" formControlName="PinCode" #postalCode class="col-9 col-lg-5 mb-3 pt-1 pb-1" placeholder="Pin code" required>
                    <span class="col-3 col-lg-2 pe-0 text-end">{{postalCode.value.length}} / 6</span>
                </div>

                <div class="inputs-div row col-12">
                    <label for="gstNumber" class="col-12 col-lg-5 ms-0 mb-2 mb-lg-0 ps-0">GST Number*</label>
                    <input type="text" formControlName="GstNo"  class="col-12 col-lg-7 mb-3 pt-1 pb-1" placeholder="GST Number" required>
                </div>
            </div>

            <div class="row col-12 ms-2">
                <div class="inputs-div row col-12">
                    <label for="BusinessType" class="col-12 col-lg-5 ms-0 mb-2 mb-lg-0 ps-0">Business Type*</label>
                    <ng-select class="select-item col-12 col-lg-7 ps-0 pe-0 mb-3" formControlName="BusinessType">
                        <ng-option value="General">General</ng-option>
                        <ng-option value="Hotel">Hotel</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="row col-12 ms-2">
                <div class="inputs-div row col-12">
                    <label for="password" class="col-12 col-lg-5 ms-0 mb-2 mb-lg-0 ps-0">Password*</label>
                    <input type="password" [type]="hide ? 'password' : 'text'" formControlName="Password" placeholder="Password" class="col-10 col-lg-6 pt-1 pb-1">
                    <a (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide" class="col-1 ps-4 ps-lg-1 align-self-center">
                    <i class="fas fa-eye col-12" [style.color]="hide ? 'var(--blacks--black-100)' : 'var(--oranges--orange-600)'"></i>
                    </a>

                    <!-- <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide" class="col-1 ps-1">
                        <mat-icon color="warn">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a> -->
                </div>
            </div>

            <div class="row col-12 ms-2 mt-3">
                <div class="login-button row col-12 mb-3">
                    <button type="submit" class="ps-2 pe-2 pt-1 pb-1 ms-0 text-center primary-button">Sign up</button>
                </div>
                <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="isEmailIDExist">
                    <strong>Email-ID is already exist. please try again !!</strong>
                </div>
            </div>
            <div class="row col-12 ms-2">
                <div class="have-account row col-12 mb-3">
                    <p class="fw-bold col-8 ps-0 pe-0">Already have an account?</p>
                    <a (click)="redirectTo('subscriber-login')" class="fw-bold col-4 ps-0 text-end">Log In</a>
                </div>
            </div>
        </form>

    </div>
</div>

<app-web-footer></app-web-footer>
